<template>
  <div class="doc-card">
    <div :class="['doc-card__icon', { 'opacity-32': !active }]">
      <r-icon
        fill="rocky"
        class="mt-1"
        :icon="icon ? icon : 'file'"
        size="16"
      />
    </div>
    <div class="doc-card__info flex-1 ml-2">
      <div :class="['doc-card__title', 'sulguni', { 'opacity-32': !active }]">
        {{ title || '—' }}
      </div>
      <div :class="['doc-card__date', 'mozzarella', 'opacity-48', 'mt-1']">
        {{ date || '—' }}
      </div>

      <div
        v-if="!active"
        class="doc-card__counters flex align-items-center"
      >
        <slot />
      </div>
    </div>

    <div class="flex align-items-center">
      <additional-menu v-if="active">
        <ul class="doc-card__menu">
          <li
            class="sulguni flex pointer"
            @click.stop.prevent="copyItem"
          >
            <r-icon
              size="16"
              fill="rocky"
              class="mr-3"
              icon="copy"
            />Дублировать
          </li>
          <li
            class="sulguni flex mt-6 pointer"
            @click.stop.prevent="confirmDelete"
          >
            <r-icon
              size="16"
              class="mr-3"
              icon="delete"
              fill="fargo"
            />Удалить
          </li>
        </ul>
      </additional-menu>
      <r-button-action
        class="doc-card__restore"
        v-else
        @click.stop.prevent="deleteItem"
        title=""
        icon="restore"
      />
    </div>
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';

export default {
  components: { AdditionalMenu },
  props: {
    uid: {
      type: String,
      required: true
    },
    icon: {
      type: [String, null],
      default: null
    },
    title: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    copyItem() {
      this.$router.push({ name: 'documents', query: { id: this.uid, copy: true } });
    },
    confirmDelete() {
      window.dispatchEvent(new CustomEvent('openModal', {
        detail: {
          name: 'confirmDeleteModal',
          props: {
            onConfirm: this.deleteItem, // функция для удаления сущности, должна вернуть промис
            header: '', // заголовок, опционально
            text: '' // опционально
          }
        }
      }));
    },
    async deleteItem() {
      const res = await this.$store.dispatch('deleteDocument', this.uid);
      if (res?.deleted) {
        this.$store.dispatch('getDocuments');
        this.$router.push({ name: 'admin' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.doc-card {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 11px 21px 11px 15px;
  border-radius: 16px;
  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }
  &__icon {
    display: flex;
    align-self: flex-start;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__date {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__counters {
    margin-top: 14px;
  }
  &__restore {
    cursor: pointer;
  }
}
.opacity-32 {
  opacity: 0.32;
}
</style>
