<template>
  <router-link
    :to="to"
    :append="append"
  >
    <div class="r-map-balloon">
      <img
        v-if="img"
        class="r-map-balloon__img"
        :src="img"
        :alt="title"
      >
      <div class="r-map-balloon__info">
        <p class="roquefort">
          {{ title }}
        </p>
        <p class="mt-1 сaprino opacity-72">
          {{ address }}
        </p>
        <div class="mt-3">
          <slot />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    append: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object, String],
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  cursor: pointer;
  width: 244px;
  max-height: 230px;
  &__img {
    width: 100%;
    height: 122px;
  }
  &__info {
    padding: 16px;
  }
}
</style>
