<template>
  <div class="objects">
    <admin-header
      title="Территории обслуживания"
      :back="false"
    />
    <r-tabs
      class="mt-7"
      :items="tabItems"
      v-model="activeTabId"
    />
    <div class="mt-6 flex">
      <router-link
        class="flex align-items-center sulguni color-rocky"
        :to="{ name: `${activeTabId.id}` }"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />{{ addButtonTitle }}
      </router-link>
      <!-- <span
          v-show="activeTabId === 'organizations'"
          class="flex align-items-center sulguni ml-6 color-rocky pointer" @click="clickUploadContainer"
      ><r-icon size="16" fill="rocky" class="mr-2" icon="add" />Загрузить данные</span
      > -->
    </div>

    <div class="objects__filter">
      <r-select
        v-show="selectedView !== 'map' && activeTabId.id === 'organizations'"
        class="flex-1 mr-6"
        :items="sortTypes"
        placeholder="Сортировать"
        v-model="selectedSortType"
      />

      <r-select
        v-show="selectedView !== 'map' && activeTabId.id === 'documents'"
        class="flex-1 mr-6"
        :items="docSortTypes"
        placeholder="Сортировать"
        v-model="selectedDocSortType"
      />

      <r-input
        class="flex-1"
        label="Поиск"
        v-model="search"
        before-icon="search"
      />

      <div
        v-show="activeTabId.id !== 'documents'"
        class="flex ml-6"
      >
        <r-button-simple
          size="larishae"
          icon="icon-view"
          @click="selectedView = 'table'"
          :type="selectedView === 'map' ? 'secondary' : 'primary'"
        />
        <r-button-simple
          size="larishae"
          v-show="activeTabId.id !== 'staff'"
          class="ml-2"
          icon="geopoint"
          :type="selectedView === 'map' ? 'primary' : 'secondary'"
          icon-size="20"
          @click="selectedView = 'map'"
        />
      </div>
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div
        class="mt-6 flex"
        v-if="activeTabId.id === 'organizations'"
      >
        <div
          class="flex align-items-center"
          v-show="selectedView === 'map'"
        >
          <r-checkbox v-model="isFilter" />

          <dropdown-select
            class="ml-1 sulguni mr-6"
            :items="problemTypes"
            @select="onProblemSelect"
          />

          <!--<drop-down-date-picker
            :start="filterStartDate"
            :end="filterEndDate"
            @changeStart="onChangeStartDate"
            @changeEnd="onChangeEndDate"
          />-->
          <date-picker-all-slots @input="changeFilterDate" />
        </div>
        <div class="ml-auto flex opacity-48 parmigiano">
          {{ !!searchText ? 'Найдено' : 'Всего' }} {{ filteredObjects.length }}
        </div>
      </div>

      <div
        v-if="selectedView === 'map' && activeTabId.id !== 'documents'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map
          v-if="isLoadYmap"
          @click="onMapClick"
          @mapInit="initMap"
          @markersWasAdd="markersWasAdd"
          :center="$cityCenter.reverse()"
        >
          <div
            v-if="problemList.length && isFilter"
            :key="problemList.length"
          >
            <!-- TODO: Координаты должны быть обязательным параметром -->
            <ymap-marker
              v-for="marker in problemList"
              :key="marker.id"
              :coords="[`${marker.lng || 0}`, `${marker.lat || 0}`]"
              :marker-id="marker.id"
              :icon="$markerIconAll(marker.color)"
              :options="{ hideIconOnBalloonOpen: false }"
              :balloon="{
                header: {
                  id: marker.id,
                  name: marker.address.length ? marker.address : marker.description,
                },
              }"
              cluster-name="problem"
            >
              <balloon-problem-card
                slot="balloon"
                :to="`/admin/appeals/${marker.id}`"
                :title="marker.title"
                :address="marker.address"
                :img="`/ajax.php?resizePhoto=${getProblemPhoto(marker.photos)}`"
                :data-id="marker.id"
                :append="true"
              />
            </ymap-marker>
          </div>

          <div
            v-if="filteredObjects.length && activeTabId.id === 'organizations'"
            :key="filteredObjects.length"
          >
            <!-- TODO: Координаты должны быть обязательным параметром -->
            <ymap-marker
              v-for="marker in filteredObjects.filter(el => el.active && el.coordinates)"
              :key="marker.id"
              :coords="marker.coordinates"
              :marker-id="marker.id"
              :icon="$markerIcon()"
              :options="{ hideIconOnBalloonOpen: false }"
              cluster-name="main"
              :balloon="{ header: { id: marker.id, name: marker.title } }"
            >
              <balloon-card
                slot="balloon"
                :to="{
                  name: activeTabId.id,
                  query: { id: marker.id },
                }"
                :title="marker.title"
                :address="marker.address"
                :img="marker.img ? `/cityProperty/rest/${marker.img}` : null"
                :data-id="marker.id"
              >
                <div class="flex mt-3 justify-space-between">
                  <r-button-action
                    class="opacity-72"
                    data-action="copy"
                    title="Дублировать"
                    icon="copy"
                  />
                  <r-button-action
                    title=""
                    icon="delete"
                    color="fargo"
                    data-action="delete"
                  />
                </div>
              </balloon-card>
            </ymap-marker>

            <!-- Обход массива геометрии (с бэка приходят как полигоны, так и мультиполигоны) -->
            <div
              v-for="district in filteredObjects"
              :key="'district_' + district.id"
            >
              <div
                v-for="(geometry, index) in district.geometry"
                :key="index"
              >
                <ymap-marker
                  :marker-id="'district_' + district.id + '-' + index"
                  marker-type="Polygon"
                  cluster="districts"
                  :coords="geometry"
                  :hint-content="district.title"
                  :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                  :marker-stroke="{ color: '#3d75e4', width: 1 }"
                  fill-rule="nonZero"
                  :options="{
                    zIndex: 9,
                  }"
                />
              </div>
            </div>
          </div>
        </rir-map>
      </div>

      <div
        v-else
        class="objects__list mt-6"
      >
        <template v-if="activeTabId.id === 'organizations'">
          <router-link
            v-for="item in filteredObjects"
            :key="item.id"
            :to="{
              name: activeTabId.id,
              query: { id: item.id },
            }"
          >
            <card
              :uid="item.id + ''"
              :img="item.img ? `/cityProperty/rest/${item.img}` : null"
              :icon="activeTabId.id === 'organizations' ? 'mayor' : 'doc'"
              :title="item.title"
              :address="item.address"
              :active="item.active"
              :current-tab="activeTabId.id"
            >
              <div
                class="flex align-items-center"
                v-if="!item.active"
              >
                <r-icon
                  size="16"
                  class="mr-2"
                  icon="block"
                  fill="fargo"
                />
                <span
                  class="briscola color-fargo"
                  :style="{ color: 'var(--rir-fargo)' }"
                >
                  Будет удалено
                  {{ formatDate(item.deleted) }}
                </span>
              </div>
              <div
                v-else
                class="flex align-cenetr"
              >
                <div class="flex">
                  <r-icon
                    size="16"
                    class="mr-2"
                    icon="file"
                    :fill="item.documents.length ? 'rocky' : 'fargo'"
                  />
                  <span
                    class="briscola mr-5"
                    :style="!item.documents.length ? { color: 'var(--rir-fargo)' } : null"
                  >{{ item.documents.length || 0 }}</span>
                  <r-icon
                    size="16"
                    class="mr-2"
                    icon="star"
                    :fill="item.rating ? 'rocky' : 'fargo'"
                  />
                  <span
                    class="briscola"
                    :style="!item.rating ? { color: 'var(--rir-fargo)' } : null"
                  >{{ item.rating || '-,-' }}</span>
                </div>
              </div>
            </card>
          </router-link>
        </template>

        <template v-else>
          <router-link
            v-for="doc in documents"
            :key="doc.id"
            :to="{
              name: activeTabId.id,
              query: { id: doc.id },
            }"
          >
            <doc-card
              :uid="String(doc.id)"
              icon="file"
              :title="doc.title"
              :date="formatDate(doc.created)"
              :active="doc.active"
              :current-tab="activeTabId.id"
            >
              <div
                class="flex align-items-center"
                v-if="!doc.active"
              >
                <r-icon
                  size="16"
                  class="mr-2"
                  icon="block"
                  fill="fargo"
                />
                <span
                  class="briscola color-fargo"
                  :style="{ color: 'var(--rir-fargo)' }"
                >
                  Будет удалено
                  {{ formatDate(doc.deleted) }}</span>
              </div>
            </doc-card>
          </router-link>
        </template>
      </div>
      <not-found v-show="filteredObjects.length < 1" />
    </template>
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';
import DropDownDatePicker from '@/components/DropDownDatePicker.vue';
import Card from '@/components/AdminCard.vue';
import DocCard from '@/components/DocumentCard.vue';
import RirMap from '@/components/RirMap.vue';
import BalloonCard from '@/components/BalloonCard.vue';
import { loadYmap } from '@/plugins/vue-yandex-maps/vue-yandex-maps.umd';
import notFound from '../components/notFound';

import { loadSvg, getStorageUrl } from '@/plugins/LoadIcons';
import Loader from '../components/Loader';
import DatePickerAllSlots from '../components/DatePickerAllSlots';
import BalloonProblemCard from '../components/BalloonProblemCard';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    Card,
    DropdownSelect,
    DropDownDatePicker,
    DocCard,
    RirMap,
    BalloonCard,
    loadYmap,
    AdminHeader,
    notFound,
    DatePickerAllSlots,
    BalloonProblemCard
  },
  data() {
    return {
      isFilter: false,
      filterStartDate: null,
      filterEndDate: null,
      problemTypes: [
        {
          id: 0,
          title: 'Все обращения',
          active: true
        },
        {
          id: 8,
          title: 'Проблемы ЖКХ',
          icon: 'cold-water'
        },
        {
          id: 2,
          title: 'Неубранный мусор',
          icon: 'city-clean'
        },
        {
          id: 12,
          title: 'Благоустройство',
          icon: 'park'
        },
        {
          id: 5,
          title: 'Незаконное строительство',
          icon: 'buildings'
        },
        {
          id: 1,
          title: 'Яма',
          icon: 'road-work'
        },

        {
          id: 13,
          title: 'Обеспечение мобильности',
          icon: 'invalid'
        },
        {
          id: 24,
          title: 'Освещение',
          icon: 'etc'
        },
        {
          id: 4,
          title: 'Другая проблема',
          icon: 'help'
        }
      ],
      sortTypes: [
        {
          id: 0,
          title: 'По названию'
        },
        {
          id: 1,
          title: 'По рейтингу'
        }
      ],
      docSortTypes: [
        {
          id: 0,
          title: 'По названию'
        },
        {
          id: 1,
          title: 'По дате'
        }
      ],
      selectedView: 'table',
      selectedSortType: 0,
      selectedDocSortType: 0,
      activeTabId: {
        id: 'organizations',
        title: 'Организации'
      },
      countOnPublic: null,
      countOnModerate: null,
      searchText: null,
      timeout: null,
      selectedMarkerId: null,
      yamap: {},
      yaMapPoint: null,
      yaMapPointEdge: null,
      drawTarget: null,
      selectedProblemType: null,
      selectedProblemId: null,
      isLoadYmap: false,
      map: null
    };
  },

  computed: {
    addButtonTitle() {
      if (this.activeTabId.id === 'organizations') {
        return 'Добавить вручную';
      } if (this.activeTabId.id === 'documents') {
        return 'Добавить документ';
      }
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },

    problemList() {
      const problemList = this.$store.state.problems.map(el => {
        const typeIcon = this.$store.state.problemTypes.find(t => t.typeIdx === el.type);
        return {
          ...el,
          typeIcon
        };
      });
      if (this.isFilter && this.selectedProblemType) {
        return problemList.filter(el => +el.type === +this.selectedProblemType);
      }

      if (!Array.isArray(problemList)) return [];

      return problemList;
    },

    documents() {
      let docList = [...this.$store.state.documents];
      if (this.searchText) {
        docList = docList.filter(el => (this.searchText ? el.title.toLowerCase().indexOf(this.searchText) !== -1 : true));
      }

      if (this.selectedDocSortType === 1) {
        docList = docList.sort((a, b) => b.id - a.id);
      }

      return docList;
    },

    filteredObjects() {
      let objects = [...this.$store.state.organizations];

      if (this.searchText) {
        objects = objects.filter(el => (this.searchText
          ? el?.title?.toLowerCase().indexOf(this.searchText) !== -1
          || el?.address?.toLowerCase().indexOf(this.searchText) !== -1
          : true));
      }

      if (this.selectedSortType === 1) {
        objects.sort((a, b) => b.rating - a.rating);
      }
      objects.sort((x, y) => ((x.active === y.active) ? 0 : x.active ? -1 : 1));

      return objects.map(el => ({
        ...el,
        geometry: el?.geometry?.type
          ? el.geometry.type === 'Polygon'
            ? [el.geometry.coordinates]
            : el.geometry.coordinates
          : [],
        coordinates: el?.coordinates?.coordinates || null,
        address: el.address || '',
        name: el.title
      }));
    },

    isLoading() {
      return this.$store.getters.getLoading;
    },

    tabItems() {
      const tabs = [
        {
          id: 'organizations',
          title: 'Организации'
        },
        {
          id: 'documents',
          title: 'Документы'
        }
      ];
      return tabs;
    }
  },

  async mounted() {
    if (this.$route.query.problemType) {
      this.selectedProblemType = this.$route.query.problemType;
      this.isFilter = true;
      this.selectedView = 'map';
      this.$router.replace({ query: null });
    }
    await this.$store.dispatch('getOrganizations');
    await this.$store.dispatch('getDocuments');
    await this.$store.dispatch('getProblems');
    // await this.$store.dispatch('getDistricts');

    const settings = { lang: 'ru_RU', coordorder: 'longlat' };
    await loadYmap(settings);

    // здесь доступен весь функционал ymap - window.ymap
    this.yaMapPoint = window.ymaps.templateLayoutFactory.createClass(
      '<div class=\'ya-map-point\'></div>'
    );
    this.yaMapPointEdge = window.ymaps.templateLayoutFactory.createClass(
      '<div class=\'ya-map-point--edge\'></div>'
    );

    this.isLoadYmap = true;
  },
  methods: {
    markersWasAdd() {
      this.centeredMap();
    },
    centeredMap() {
      if (this.map && this.filteredObjects.length > 0) {
        const pointsList = [];
        if (this.filteredObjects.length > 0) {
          this.filteredObjects.forEach(item => {
            if (item.coordinates && item?.coordinates[0] != 0) {
              pointsList.push(item.coordinates);
            }
          });
        }
        this.$nextTick(() => {
          if (pointsList.length > 0) {
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 100,
              duration: 300
            });
          }
        });
      }
    },
    initMap(e) {
      this.map = e;
    },
    clickUploadContainer() {
      this.$refs.upload.uploadContainer();
    },
    confirmDelete() {
      event.preventDefault();
      event.stopPropagation();
      window.dispatchEvent(new CustomEvent('openModal', {
        detail: {
          name: 'confirmDeleteModal',
          props: {
            onConfirm: this.onDelete, // функция для удаления сущности, должна вернуть промис
            header: '', // заголовок, опционально
            text: '' // опционально
          }
        }
      }));
    },
    fetchProblems() {
      const data = {};
      this.isFilter
      && (data.filterTypes = this.selectedProblemType ? String(this.selectedProblemType) : '');
      this.filterStartDate && (data.dateFrom = this.filterStartDate);
      this.filterEndDate && (data.dateTo = this.filterEndDate);
      this.$store.dispatch('getProblems', data);
    },

    onChangeStartDate(date) {
      if (typeof date === 'string') {
        this.filterStartDate = date;
        this.fetchProblems();
      } else {
        this.filterStartDate = null;
      }
    },

    onChangeEndDate(date) {
      if (typeof date === 'string') {
        this.filterEndDate = date;
        this.fetchProblems();
      } else {
        this.filterEndDate = null;
      }
    },
    changeFilterDate(date) {
      this.filterStartDate = date[0];
      this.filterEndDate = date[1];
      this.fetchProblems();
    },
    onProblemSelect(item) {
      this.problemTypes = this.problemTypes.map(el => ({ ...el, active: item.id === el.id }));
      this.selectedProblemType = item.id;
      this.isFilter = true;
    },

    getProblemPhoto(arr) {
      return arr?.[0]?.url || null;
    },

    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },

    onClickProblemMarker(id) {
      this.selectedProblemId = id;
    },

    onMapClick(e) {
      this.selectedMarkerId = null;
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;
      const buttons = this.$refs.map.querySelectorAll(
        `.rir-map-balloon[data-id="${this.selectedMarkerId}"] .rir-button`
      );
      // rir-map-balloon

      const balloon = this.$refs.map.querySelector(`.rir-map-balloon[data-id="${this.selectedMarkerId}"]`);

      balloon.addEventListener('click', this.openCard);

      console.log('bindListener', balloon);

      buttons.forEach(button => {
        if (button.getAttribute('data-action') === 'copy') {
          button.addEventListener('click', this.onCopy);
        } else {
          button.addEventListener('click', this.confirmDelete);
        }
      });
    },

    unbindListener(id) {
      const buttons = this.$refs.map.querySelectorAll('.rir-map-balloon .rir-button');

      buttons.forEach(button => {
        if (button.getAttribute('data-id') === 'copy') {
          button.removeEventListener('click', this.onCopy);
        } else {
          button.removeEventListener('click', this.onDelete);
        }
      });
    },

    // Редирект на модуль "проблемы"
    routeToProblem(e) {
      if (e.target.closest('.rir-map-balloon__info')) {
        window.location.href = `/admin/appeals/${this.selectedProblemId}?from=${
          window.location.pathname
        }?problemType=${this.selectedProblemType || 0}`;
      }
    },

    bindProblemListener(e) {
      this.$refs.map.addEventListener('click', this.routeToProblem);
    },

    unbindProblemListener() {
      this.$refs.map.removeEventListener('click', this.routeToProblem);
    },

    openCard() {
      this.$router.push({
        name: this.activeTabId.id,
        query: { id: this.selectedMarkerId }
      });
    },
    onCopy() {
      console.log('onCopy');
      event.preventDefault();
      event.stopPropagation();
      this.$router.push({
        name: 'organizations',
        query: { id: this.selectedMarkerId, copy: true }
      });
    },

    async onDelete() {
      const res = await this.$store.dispatch('deleteOrganization', this.selectedMarkerId);

      if (res?.updated) {
        this.$store.dispatch('getOrganizations');
      }
    },

    formatDate(date) {
      return new Date(date).toLocaleString('Ru-ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    },

    loadObjects(typeId) {
      this.$store.dispatch('loadObjects', typeId);
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}
</style>

<style lang="scss">

.ymaps-2-1-79-image-with-content{
  & * {
    fill: #FFFFFF;
  }
}
</style>
