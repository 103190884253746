<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="r-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
      >
        <template #after>
          <r-icon
            icon="search"
            fill="pianist"
            size="16"
          />
        </template>
      </r-input>
    </div>
    <div>
      <l-map
        :editable="polygonDrawing"
        v-if="isYmapsReady"
        ref="myMap"
        :key="count"
        @click="clickMap"
        :options="{ zoomControl: false, editable: true, attributionControl: false  }"
        :crs="crs"
        :style="position"
        :center="center || getCityCenter"
        :zoom="mapZoom"
        @update:bounds="getBoundsAction"
        @update:zoom="$emit('actionEndChange', $event)"
        @ready="onMapInit"
        @editable:drawing:end="polygonDrawingEnd"
        @dblclick="managePolygon"
        @click.right="managePolygon"
      >
        <l-control class="example-custom-control">
          <r-button-simple
            style="display: block;"
            v-if="collapseBtn"
            class="mb-4 amelie"
            :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
            fill="rocky"
            icon-size="20"
            type="light"
            @click="onCollapseMap"
            size="larishae"
            title=""
          />
        </l-control>
        <l-control class="example-custom-control">
          <r-button-simple
            style="display: block;"
            type="light"
            size="larishae"
            icon="add"
            @click="onZoom(1)"
            class="amelie"
            fill="rocky"
            icon-size="20"
            title=""
          />
        </l-control>
        <l-control class="example-custom-control">
          <r-button-simple
            style="display: block;"
            type="light"
            size="larishae"
            class="mt-1 mb-4 amelie"
            icon="remove"
            fill="rocky"
            icon-size="20"
            @click="onZoom(-1)"
            title=""
          />
        </l-control>
        <l-tile-layer
          :key="count"
          :url="layer.url"
          :attribution="layer.attribution"
        />
        <l-control-attribution position="bottomright" prefix="">
        </l-control-attribution>
        <slot />
      </l-map>
    </div>
    <div
      v-show="control"
      class="r-map__controls"
    >
      <!--<r-button-simple
        v-if="drawPolygon"
        class="mt-1 mb-4"
        icon="add-directory"
        :type="polygonDrawing ? 'primary' : 'light'"
        size="larishae"
        icon-size="20"
        @click="managePolygon"
        title=""
      />-->
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        title=""
      />
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LControl, LControlAttribution } from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import '../plugins/ui/MarkerCluster.Default.css';
import '../plugins/ui/leaflet.css';
// import LeafletFullscreen from 'leaflet-fullscreen';
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.min.css';

import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
export default {
  name: 'RirMapLeaflet',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlAttribution,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    LControl
  },
  props: {
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    legend: {
      type: Boolean,
      default: true
    },
    center: {
      type: Array,
      default: () => [0, 0]
    },
    zoom: {
      type: Number,
      default: 14
    },
    maxZoom: {
      type: Number,
      default: 22
    },
    minZoom: {
      type: Number,
      default: 10
    },
    drawPolygon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layer: {
        url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.28-0-b210701140430&x={x}&y={y}&z={z}&scale=2&lang=ru_RU',
        attribution: `
        <div style="display: flex; align-items: center;">© Яндекс
          <a style="margin-left: 4px; color: black;" href='https://yandex.ru/legal/maps_termsofuse/?lang=ru'>
              Условия использования
          </a>
          <img style="margin: 0 4px;" src="${this.$storage}/common/icons/other/map/yandex-logo.svg"/>
        </div>`,
      },
      crs: L.CRS.EPSG3395,
      position: {
        position: 'absolute',
        width: '100%',
        height: '100%'
      },
      count: 0,
      isYmapsReady: true,
      mapControls: [], // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        // restrictMapArea: [
        //   [54.982611, 43.223491],
        //   [54.817007, 43.482356]
        // ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },

      clusterOptions: {
        main: {
          //   clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: false,

          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]

          //   Шрифт кластера
          //   clusterIconContentLayout:
          //     '<p style="color: #FFFFFF; font-weight: bold;font-size: 24px; position: absolute;">$[properties.geoObjects.length]</p>'
        }
      },

      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true,
      bounds: null,
      polygonDrawing: false
    };
  },
  computed: {
    getCityCenter() {
      return this.$cityCenter || [];
    }
  },
  mounted() {
    this.$emit('mapInit', this.$refs.myMap);
    this.$refs.myMap.mapObject.on('click', e => {
      this.$emit('onMapClick', e);
    });
    const map = this.$refs.myMap.mapObject;
    map.addControl(new L.Control.Fullscreen());
  },
  methods: {
    onMapInit(e) {
      this.mapInstance = e;
    },
    polygonDrawingEnd(e) {
      this.$emit('polygonDrawingEnd', e.layer);
    },
    managePolygon() {
      this.polygonDrawing = !this.polygonDrawing;
      const deletePolygon = e => {
        this.$refs.myMap.removeLayer(e.relatedTarget);
      };
      const stopDrawing = () => {
        this.$refs.myMap.mapObject.editTools.stopDrawing();
        this.$refs.myMap.mapObject.editTools.commitDrawing();
      };
      if (this.polygonDrawing) {
        this.$refs.myMap.mapObject.editTools.startPolygon(null, {
          color: '#3d75e4',
          weight: 1,
          fillColor: '#3d75e4',
          fillOpacity: '0.4',
          layerType: 'polygon',
          name: 'polygon',
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuInheritItems: false,
          contextmenuItems: [
            {
              icon: 'data:image/svg+xml;charset=UTF-8,%3csvg width=\'16\' height=\'16\' viewBox=\'0 0 16 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath d=\'M5 2C5 0.89543 5.89543 0 7 0H9C10.1046 0 11 0.895431 11 2V3H14C14.5523 3 15 3.44772 15 4C15 4.55228 14.5523 5 14 5H2C1.44772 5 1 4.55228 1 4C1 3.44772 1.44772 3 2 3H5V2ZM9 2H7V3H9V2Z\' fill=\'%23E14761\'/%3e%3cpath d=\'M4 7C4 6.44772 3.55228 6 3 6C2.44772 6 2 6.44772 2 7V13C2 14.6569 3.34315 16 5 16H11C12.6569 16 14 14.6569 14 13V7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7V13C12 13.5523 11.5523 14 11 14H5C4.44772 14 4 13.5523 4 13V7Z\' fill=\'%23E14761\'/%3e%3cpath d=\'M5.79289 7.29289C6.18342 6.90237 6.81658 6.90237 7.20711 7.29289L8 8.08579L8.79289 7.29289C9.18342 6.90237 9.81658 6.90237 10.2071 7.29289C10.5976 7.68342 10.5976 8.31658 10.2071 8.70711L9.41421 9.5L10.2071 10.2929C10.5976 10.6834 10.5976 11.3166 10.2071 11.7071C9.81658 12.0976 9.18342 12.0976 8.79289 11.7071L8 10.9142L7.20711 11.7071C6.81658 12.0976 6.18342 12.0976 5.79289 11.7071C5.40237 11.3166 5.40237 10.6834 5.79289 10.2929L6.58579 9.5L5.79289 8.70711C5.40237 8.31658 5.40237 7.68342 5.79289 7.29289Z\' fill=\'%23E14761\'/%3e%3c/svg%3e',
              text: 'Удалить область',
              callback: deletePolygon
            },
            {
              text: 'Завершить рисование',
              callback: stopDrawing
            }
          ]
        });
      } else {
        stopDrawing();
      }
    },
    mapZoomAdd(zoom) {
      if (zoom < 15) {
        this.mapZoom = 15;
      }
    },
    mapZoomFunc() {
      return this.$refs.myMap.mapObject.getZoom();
    },
    getBounds() {
      return this.$refs.myMap.mapObject.getBounds();
    },
    getBoundsAction(item) {
      this.bounds = item;
    },
    mapDelete() {
      this.mapZoom = 17;
      this.isYmapsReady = false;
    },
    mapInit() {
      this.mapZoom = 17;
      this.isYmapsReady = true;
      this.$emit('my-event');
    },
    reloadMap() {
      this.$refs.myMap.mapObject.invalidateSize();
      this.count++;
    },
    clickMap() {
      this.$emit('clickMap', this.$refs.myMap);
    },
    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    onCollapseMap() {
      const fullscreenButton = document.querySelector('.leaflet-control-fullscreen-button')
      fullscreenButton.click()
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .leaflet-control-fullscreen {
  width: 0;
  height: 0;
  visibility: hidden;
}
.r-map {
  height: 100%;
  width: 100%;
  position: sticky;
  border-radius: 24px;
  overflow: hidden;
}
.r-map__search {
  position: absolute;
  border-radius: 8px;
  z-index: 10;
  top: 16px;
  left: 16px;
  display: flex;
  width: 255px;
  background-color: #fff;
  box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
}
.r-map__search > * {
  background-color: #fff;
}
.r-map__controls {
  z-index: 5000;
  display: block !important;
  position: absolute;
  top: 16px;
  right: 16px;
}
.r-map__controls > * {
  width: 40px;
  height: 40px;
  box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
}
.r-map__controls--fixed {
  position: fixed;
  right: 16px;
  top: 16px;
  z-index: 10001;
}
.r-map--fixed {
  background-color: #ccc;
  border-radius: unset;
  position: fixed;
  height: 100% !important;
  top: 0;
  left: 0;
  z-index: 999;
}
.r-map--fixed .vue2leaflet-map {
  width: 100vw;
  height: 100vh;
}
.r-map__legend {
  z-index: 5000;
  padding: 8px 12px;
  background: var(--rir-amelie);
  position: absolute;
  left: 16px;
  bottom: 16px;
  border-radius: 4px;
}
.legend__color {
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
@media screen and (max-width: 1023px) {
  .r-map__search {
    display: none;
  }
}
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}
::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}
::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
}
::v-deep .ymaps-2-1-79-balloon__tail, .ymaps-2-1-79-balloon__tail:after {
  display: none;
}
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}
::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}
::v-deep .leaflet-contextmenu {
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
::v-deep .leaflet-contextmenu a.leaflet-contextmenu-item {
  font-family: RirFont,sans-serif;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #04153E;
  border-top: 0;
  border-bottom: 0;
}
::v-deep .leaflet-contextmenu a.leaflet-contextmenu-item:hover {
  border-top: 0;
  background-color: var(--rir-walleHover);
  border-bottom: 0;
}
::v-deep .leaflet-attribution-flag {
  display: none !important;
}
</style>

<style>
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
.ymaps-2-1-79-float-button {
  display: none !important;
}
.leaflet-attribution-flag {
  display: none;
}
div.leaflet-marker-icon.leaflet-interactive {
  width: 12px !important;
  height: 12px !important;
  margin-left: -6px !important;
  margin-top: -6px !important;
  border-radius: 6px !important;
}
</style>
