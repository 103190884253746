<template>
  <div class="obj-card">
    <div :class="['obj-card__img', { 'opacity-32': !active }]">
      <img
        v-if="img"
        :src="img"
        alt=""
        loading="lazy"
      >
      <r-icon
        v-else
        :icon="icon ? icon : 'mayor'"
        size="32"
        fill="alien"
      />
    </div>
    <div class="obj-card__info flex-1">
      <div :class="['obj-card__title sulguni', { 'opacity-32': !active }]">
        {{ title }}
      </div>
      <div :class="['obj-card__address mozzarella', { 'opacity-32': !active }]">
        {{ address }}
      </div>

      <div class="obj-card__counters flex align-items-center">
        <slot />
      </div>
    </div>

    <div class="flex align-items-center">
      <additional-menu v-if="active">
        <ul class="obj-card__menu">
          <li
            class="sulguni flex pointer"
            @click.stop.prevent="copyItem"
          >
            <r-icon
              size="16"
              fill="rocky"
              class="mr-3"
              icon="copy"
            />Дублировать
          </li>
          <li
            class="sulguni flex mt-6 pointer"
            @click.stop.prevent="confirmDelete"
          >
            <r-icon
              size="16"
              class="mr-3"
              icon="delete"
              fill="fargo"
            />Удалить
          </li>
        </ul>
      </additional-menu>
      <r-button-action
        class="obj-card__restore"
        v-else
        @click.stop.prevent="onDeleteItem"
        title=""
        icon="restore"
      />
    </div>
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';

export default {
  components: { AdditionalMenu },
  props: {
    uid: {
      type: String,
      required: true
    },
    img: {
      type: [String, null],
      default: null
    },
    icon: {
      type: [String, null],
      default: null
    },
    title: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    }
  },
  computed: {
    actionPostfix() {
      let postfix = null;
      if (this.currentTab === 'districts') {
        postfix = 'District';
      } else if (this.currentTab === 'staff') {
        postfix = 'Staff';
      } else if (this.currentTab === 'institutions') {
        postfix = 'Institution';
      }
      return postfix;
    }
  },
  methods: {
    async onRestore() {
      console.log('restore', this.uid);

      if (this.actionPostfix) {
        const res = await this.$store.dispatch(`set${this.actionPostfix}`, {
          id: this.uid,
          hidden: 0
        });
        res && this.$store.dispatch(`get${this.actionPostfix}`);
      }

      // this.actionPostfix &&
      // this.$store.dispatch(`set${this.actionPostfix}`, { id: this.uid, hidden: 0 });
      // if (!res.error) {
      //   this.$store.dispatch('loadObjects');
      // }
    },
    copyItem() {
      this.$router.push({ name: 'organizations', query: { id: this.uid, copy: true } });
    },
    confirmDelete() {
      window.dispatchEvent(new CustomEvent('openModal', {
        detail: {
          name: 'confirmDeleteModal',
          props: {
            onConfirm: this.onDeleteItem, // функция для удаления сущности, должна вернуть промис
            header: '', // заголовок, опционально
            text: '' // опционально
          }
        }
      }));
    },
    async onDeleteItem() {
      const res = await this.$store.dispatch('deleteOrganization', this.uid);
      if (res?.updated) {
        this.$store.dispatch('getOrganizations');
        this.$router.push({ name: 'admin' });
      }
      // let actionName = null;
      // if (this.currentTab === 'districts') {
      //   actionName = 'deleteDistrict';
      // } else if (this.currentTab === 'staff') {
      //   actionName = 'deleteStaff';
      // } else if (this.currentTab === 'institutions') {
      //   actionName = 'deleteInstitution';
      // }

      // actionName && this.$store.dispatch(actionName, this.uid);
      // this.actionPostfix && (await this.$store.dispatch(`delete${this.actionPostfix}`, this.uid));

      // if (this.actionPostfix) {
      //   const res = await this.$store.dispatch(`delete${this.actionPostfix}`, this.uid);
      //   res && this.$store.dispatch(`get${this.actionPostfix}`);
      // }
      // this.actionPostfix && (await this.$store.dispatch(`delete${this.actionPostfix}`, this.uid));
    }
  }
};
</script>

<style lang="scss" scoped>
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 15px 21px 15px 15px;
  border-radius: 16px;
}
.obj-card:hover {
  box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
}
.obj-card__img {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--r-arrival);
  margin-right: 12px;
  width: 72px;
  height: 72px;
  border-radius: 8px;
}
.obj-card__img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.obj-card__info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.obj-card__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.obj-card__address {
  opacity: 0.72;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.obj-card__counters {
  margin-top: 14px;
}
.obj-card__restore {
  background-color: transparent;
  cursor: pointer;
}
.obj-card .pointer {
  cursor: pointer;
}
.opacity-32 {
  opacity: 0.32;
}
</style>
