import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import Documents from '../views/Documents.vue';
import Organization from '../views/Organization.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,
    children: [
      {
        path: '',
        component: ObjectList
      },
      {
        name: 'documents',
        path: 'documents',
        component: Documents
      },
      {
        name: 'organizations',
        path: 'organizations',
        component: Organization
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   console.log('ROUTER_BEFORE_EACH');

//   try {
//     if (!store.getters.getUser?.userId) {
//       console.log('THEEEN');
//       store.dispatch('getUserInfo').then(() => {
//         if (+store.getters.getUser?.userId > 0) {
//           console.log('USER', store.getters.getUser, +store.getters.getUser?.userId > 0);
//           next();
//         } else {
//           console.log('No AUTH');
//           window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
//         }
//       });
//       console.log('AFTEER');
//     } else {
//       if (+store.getters.getUser?.userId > 0) {
//         next();
//       } else {
//         console.log('userId > 0', store.getters.getUser);
//         window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
//       }
//     }
//   } catch (e) {
//     console.log(e);
//     store.commit('setUser', null);
//     window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
//     // next({
//     //   name: 'dashboard' // back to safety route //
//     // });
//   }
// });

export default router;
