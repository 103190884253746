<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="r-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
        after-icon="search"
      />
    </div>

    <yandex-map
      v-if="isYmapsReady"
      :coords="center || $cityCenter.reverse()"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      @dblclick="onMapDblClick"
      :options="mapOptions"
      :behaviors="['drag', 'scrollZoom', 'multiTouch']"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      :cluster-options="clusterOptions"
      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
    >
      <slot />
    </yandex-map>

    <div
      v-show="control"
      class="r-map__controls flex flex-direction-column"
    >
      <r-button-simple
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="onCollapseMap"
        type="light"
        size="larishae"
        title=""
      />
      <drop-down-map-button
        v-if="showType"
        class="mb-4"
        :items="mapViewTypes"
        @select="onChangeView"
      />
      <r-button-simple
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
        title=""
      />
    </div>
  </div>
</template>

<script>
import BalloonCard from './BalloonCard.vue';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';
import DropDownMapButton from './DropDownMapButton.vue';

export default {
  name: 'RMap',
  components: {
    BalloonCard,
    DropDownMapButton
  },
  props: {
    showType: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    center: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 10
    },

    dblclickTimeout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      mapControls: [], // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },

      clusterOptions: {
        // Название свойства = название кластера (к которому будет применен данный стиль)
        main: {
          //   clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: true,
          clusterBalloonLayout: [
            '<ul class="list" style="margin: 24px;max-width:250px;  max-height: 200px;overflow-y: scroll;">',
            '{% for geoObject in properties.geoObjects %}',
            '<li class="mt-2">'
            + '<a href="/admin/service-area/organizations?id={{ geoObject.properties.balloonContentHeader.id|raw }}" class="list_item" >'
            + '{{ geoObject.properties.balloonContentHeader.name|raw }}</a></li>',
            '{% endfor %}',
            '</ul>'
          ].join(''),

          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        },
        problem: {
          clusterOpenBalloonOnClick: true,
          clusterBalloonLayout: [
            '<ul class="list" style="margin: 24px;max-width:250px;  max-height: 200px;overflow-y: scroll;">',
            '{% for geoObject in properties.geoObjects %}',
            '<li class="mt-2">'
            + '<a href="/admin/appeals/{{ geoObject.properties.balloonContentHeader.id|raw }}" class="list_item" >'
            + '{{ geoObject.properties.balloonContentHeader.name|raw }}</a></li>',
            '{% endfor %}',
            '</ul>'
          ].join(''),
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster_orange.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        },
        home: {
          //   clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: true,
          clusterBalloonLayout: [
            '<ul class="list" style="margin: 24px;max-width:250px;  max-height: 200px;overflow-y: scroll;">',
            '{% for geoObject in properties.geoObjects %}',
            '<li class="mt-2">'
            + '<p class="list_item" >'
            + '{{ geoObject.properties.balloonContentHeader.name|raw }}</p></li>',
            '{% endfor %}',
            '</ul>'
          ].join(''),

          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        },
      },

      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true,
      isYmapsReady: false
    };
  },

  async mounted() {
    // настройки карты
    const settings = {
      coordorder: 'latlong',
      apiKey: 'f8217e42-9b86-4033-8411-a7bf4f8d6835'
    };

    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },

  beforeDestroy() {
    this.mapInstanse && this.mapInstanse.destroy();
  },

  methods: {
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.mapInstanse.container.fitToViewport();
      });
    },

    onMapInit(e) {
      console.log('mapInit | objects - ', e.geoObjects.getLength(), e.geoObjects);
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.$emit('mapInit', e);
      this.mapInstanse.options.set('openBalloonOnClick', false);
    },

    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },

    onLocation() {},

    onMapClick(e) {
      //   if (this.markers.length) return;
      const coords = e.get('coords');
      this.coords = coords;
      //   Закрываем открытый Balloon при клике по карте

      if (this.dblclickTimeout) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
          this.$emit('click', coords);
        }, this.dblclickTimeout);
      } else {
        this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
        this.$emit('click', coords);
      }
    },

    onMapDblClick(e) {
      const coords = e.get('coords');
      //   Закрываем открытый Balloon при клике по карте
      if (this.dblclickTimeout && this.timeout) clearTimeout(this.timeout);
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      this.$emit('dblclick', coords);
    },

    onRightClick(e) {
      this.$emit('onRightClick', e);
    },

    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
    > * {
      background-color: #fff;
    }
  }
  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }
    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }
  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  .r-map {
    &__search {
      display: none;
    }
  }
}

// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}
::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<style>
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-family: 'Golos UI';
  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}
</style>
