const S3_COMMON_ALL = process.env.VUE_APP_S3_COMMON_PATH_ALL;

export const getStorageUrl = iconName => {
  if (iconName) {
    const size = iconName.replace(/.+_/, '');
    const prefix = `${S3_COMMON_ALL}/icons/`;

    if (size) {
      return `${prefix}${size}/${iconName}.svg`;
    }

    return `${prefix}${size}/${iconName}.svg`;
  }

  return '';
};

export const loadSvg = async src => new Promise((resolve, reject) => {
  fetch(src)
    .then(r => r.text())
    .then(text => {
      // el.innerHTML = text;
      // const svgData = getSvgData(text)
      resolve(text);
    })
    .catch(console.error.bind(console));
});

// if (cache[src]) {
//   return Promise.resolve(cache[src])
// }
//
// if (!pendingRequests[src]) {
//   pendingRequests[src] = new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest()
//
//     xhr.onload = () => {
//       if (xhr.status !== 200) {
//         reject(InlineSvg: Request failed: ${src}, status: ${xhr.status})
//       }
//       else if (xhr.response === null) {
//         reject(InlineSvg: Empty response: ${src})
//       }
//       else {
//         const svgData = getSvgData(xhr.response)
//
//         cache[src] = svgData
//         resolve(svgData)
//       }
//
//       delete pendingRequests[src]
//     }
//
//     xhr.onerror = () => {
//       reject(InlineSvg: Request failed: ${src})
//       delete pendingRequests[src]
//     }
//
//     xhr.open('GET', src, true)
//     xhr.responseType = 'text'
//     xhr.send()
//   })
// }
//
// return pendingRequests[src]

// loadSvg(getStorageUrl(name))
//   .then(({ body, svgAttributes }) => {
//     const _svgAttributes = { class: 'inline-block', role: 'img', ...svgAttributes }
//
//     const attributes = Object.keys(_svgAttributes)
//       .map((attribute) => `${attribute}="${_svgAttributes[attribute]}"`)
//       .join(' ')
//
//     return `<svg ${attributes}>${body}</svg>`
//   })
