<template>
  <div class="organization-form">
    <admin-header
      :title="!isEditPage ? 'Новая организация' : (name ? name : 'Редактирование организации')"
    >
      <sup
        class="text-c13 color-fargo"
        v-if="!isSave"
        v-show="isEditPage"
      >Не сохранено</sup>
    </admin-header>
    <loader v-if="loadData" />
    <div
      class="flex mt-8 "
      v-else
    >
      <div class="organization-form__form flex flex-1 flex-direction-column">
        <div class="flex">
          <img-uploader
            class="organization-form__img-cont mr-6"
            @change="onUpload"
            @onViewImage="onViewImage"
            @deleteFile="deleteFile"
            :src="imageUrl ? `/cityProperty/rest/${imageUrl}` : null"
          />

          <div class="flex-1">
            <r-input
              @blur="isChange('name')"
              label="Наименование"
              v-model="name"
            />
            <r-input
              @blur="isChange('houseCount')"
              class="mt-6"
              label="Всего домов"
              v-model="houseCount"
            />
          </div>
        </div>

        <!-- <r-input @blur="isChange('blockHouseCount')" class="mt-6" label="Дома с блокированной застройкой" v-model="blockHouseCount" /> -->
        <r-textarea
          class="mt-6"
          label="Предоставляемые услуги"
          v-model="description"
          @input="isChange('description')"
        />
        <r-textarea
          class="mt-6"
          label="График работы"
          v-model="workHours"
          @input="isChange('workHours')"
        />

        <r-input
          @blur="isChange('web')"
          class="mt-10"
          label="Сайт"
          v-model="web"
        />

        <div class="flex mt-6">
          <div class="flex-1 mr-2">
            <r-input
              @onPressEnter="addPhone"
              label="Телефон"
              v-model="phone"
              v-mask="'+7 (###) ###-##-##'"
            />
            <contact-items
              v-show="phoneList.length"
              class="mt-4"
              v-model="phoneList"
            />
          </div>
          <r-button-simple
            size="larishae"
            @click="addPhone"
            icon="add"
            :disabled="!phone"
          />
        </div>

        <div class="mt-6 flex align-start">
          <div class="flex-1 mr-2">
            <r-input
              label="E-mail"
              @onPressEnter="addMail"
              v-model="mail"
            />
            <contact-items
              v-show="mailList.length"
              class="mt-4"
              icon="mail"
              v-model="mailList"
            />
          </div>
          <r-button-simple
            size="larishae"
            @click="addMail"
            icon="add"
            :disabled="!mail"
          />
        </div>

        <div class="mt-6 flex align-start">
          <div class="flex-1 mr-2">
            <r-select
              :items="documentList"
              label="Документы"
              v-model="document"
              return-object
            />
            <contact-items
              class="mt-4"
              v-show="selectedDocuments.length"
              icon="attach"
              text-value="value"
              sub-value="position"
              v-model="selectedDocuments"
            />
          </div>
          <r-button-simple
            size="larishae"
            @click="addDocument"
            icon="add"
            :disabled="!documentList || !document.id"
          />
        </div>
      </div>
      <div class="ml-6 flex flex-direction-column flex-1 overflow-hidden">
        <rir-map-leaflet
          draw-polygon
          @mapInit="onMapInit"
          @onMapClick="onMapClick"
          :center="coordinates.length > 0 ? coordinates : mapCenter"
          @polygonDrawingEnd="polygonDrawingEnd"
        >
          <!-- районы УК-->
          <l-geo-json
            :geojson="filteredObjects"
            :options-style="{
              weight: 1,
              fillColor: '#BEBEBE',
              fillOpacity: 0.4,
              color: '#BEBEBE',
            }"
          />
          <!-- <div
           v-for="district in "
           :key="'district_' + district.id"
         >

          <div
              v-for="(geometry, index) in district.geometry"
              :key="index"
            >

              <l-polygon
                :lat-lngs="geometry"
                :color="'#BEBEBE'"
                :weight="1"
                :fill-color="'#BEBEBE'"
                :fill-opacity="0.4"
              >
                <l-tooltip>Участок {{district.name}}</l-tooltip>
              </l-polygon>
           </div>
          </div>-->

          <!--Драг маркер -->
          <l-marker
            :lat-lng="coordinates || mapCenter"
            v-if="coordinates.length"
            @update:lat-lng="onDragMarker"
            draggable
          >
            <l-icon
              :icon-url="$markerIcon().imageHref"
            />
          </l-marker>

          <!--дома относящиеся к УК -->
          <l-marker
            v-for="home of buildings"
            :key="home.id"
            :lat-lng="[home.lat, home.lon]"
          >
            <l-icon
              :icon-url="$markerHomeIcon().imageHref"
            />
            <l-tooltip>{{ home.address }}</l-tooltip>
          </l-marker>

          <!-- Участки КУМИ-->
          <div v-if="showRegions">
            <!-- Обход массива районов -->
            <l-geo-json
              :geojson="$store.state.regions"
              :options="options"
              :options-style="{
                weight: 1,
                fillColor: '#9954F2',
                fillOpacity: 0.4,
                color: '#9954F2',
                interactive: true,
                pane: 'tilePane',
              }"
            />
            <!-- <div
              v-for="region in $store.state.regions"
              :key="region.id"
            >
              <l-polygon
                :lat-lngs="getRegionCoords(region)"
                color="#9954F2"
                :weight="1"
                fill-color="#9954F2"
                :fill-opacity="0.4"
              >
                <l-popup>
                  {{ region.cadastralNum }}
                </l-popup>
              </l-polygon>
            </div>-->
          </div>

          <!-- Интерактивный полигон-->
          <l-layer-group v-if="geometry.length > 0">
            <div
              v-for="(pol, index) in geometry[0]"
              :key="index + '_polygon'"
            >
              <editable-polygon
                :lat-lngs="pol"
                :color="'#3d75e4'"
                :weight="1"
                :fill-color="'#3d75e4'"
                :fill-opacity="0.4"
                @click="editPolygon = !editPolygon"
                :editable="editPolygon"
                :options="{
                  name: 'polygon',
                  noClip: true,
                  contextmenu: true,
                  contextmenuWidth: 140,
                  contextmenuInheritItems: false,
                  contextmenuItems: [{
                    icon: 'data:image/svg+xml;charset=UTF-8,%3csvg width=\'16\' height=\'16\' viewBox=\'0 0 16 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath d=\'M5 2C5 0.89543 5.89543 0 7 0H9C10.1046 0 11 0.895431 11 2V3H14C14.5523 3 15 3.44772 15 4C15 4.55228 14.5523 5 14 5H2C1.44772 5 1 4.55228 1 4C1 3.44772 1.44772 3 2 3H5V2ZM9 2H7V3H9V2Z\' fill=\'%23E14761\'/%3e%3cpath d=\'M4 7C4 6.44772 3.55228 6 3 6C2.44772 6 2 6.44772 2 7V13C2 14.6569 3.34315 16 5 16H11C12.6569 16 14 14.6569 14 13V7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7V13C12 13.5523 11.5523 14 11 14H5C4.44772 14 4 13.5523 4 13V7Z\' fill=\'%23E14761\'/%3e%3cpath d=\'M5.79289 7.29289C6.18342 6.90237 6.81658 6.90237 7.20711 7.29289L8 8.08579L8.79289 7.29289C9.18342 6.90237 9.81658 6.90237 10.2071 7.29289C10.5976 7.68342 10.5976 8.31658 10.2071 8.70711L9.41421 9.5L10.2071 10.2929C10.5976 10.6834 10.5976 11.3166 10.2071 11.7071C9.81658 12.0976 9.18342 12.0976 8.79289 11.7071L8 10.9142L7.20711 11.7071C6.81658 12.0976 6.18342 12.0976 5.79289 11.7071C5.40237 11.3166 5.40237 10.6834 5.79289 10.2929L6.58579 9.5L5.79289 8.70711C5.40237 8.31658 5.40237 7.68342 5.79289 7.29289Z\' fill=\'%23E14761\'/%3e%3c/svg%3e',
                    text: 'Удалить область',
                    callback: deletePolygon,
                  }],
                }"
              />
              <!--  <ymap-marker
                  @click="onPolygonClick"
                  @dblclick="onPolygonDblClick"
                  @geometrychange="geometryChange"
                  :marker-id="index + '_territory'"
                  marker-type="Polygon"
                  :coords="pol"
                  :hint-content="`Участок` + index"
                  :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                  :marker-stroke="{ color: '#3d75e4', width: 1 }"
                  :options="{
                    /** Недокументированная опция!
                  Задает активную область над кастомной иконкой точки (editorVertexLayout),
                  тем самым включает взаимодействие с ней */
                    editorVertexIconShape: {
                      type: 'Rectangle',
                      coordinates: [
                        [-4, -4],
                        [4, 4],
                      ],
                    },
                    /** Недокументированная опция!
                  Задает активную область над промежуточнй точкой (editorVertexLayout),
                  тем самым включает взаимодействие с ней */
                    editorEdgeIconShape: {
                      type: 'Rectangle',
                      coordinates: [
                        [-4, -4],
                        [4, 4],
                      ],
                    },
                    editorVertexLayout: yaMapPoint,
                    editorVertexLayoutHover: yaMapPoint,
                    editorVertexLayoutActive: yaMapPoint,
                    editorVertexLayoutDrag: yaMapPoint,
                    editorEdgeLayout: yaMapPointEdge,
                    editorEdgeLayoutHover: yaMapPointEdge,
                    editorEdgeLayoutActive: yaMapPointEdge,
                    editorEdgeLayoutDrag: yaMapPointEdge,
                  }"
                  :icon="$markerIcon()"
                  fill-rule="nonZero"
                >

                </ymap-marker>-->
            </div>
          </l-layer-group>
        </rir-map-leaflet>
        <!-- {{ coordinates }} -->
        <!-- {{ geometry }} -->
        <!-- {{ newPolygonIndex }} -->
        <!-- :center="coordinates.length ? coordinates : $cityCenter" -->
        <!-- {{ districts.length }} -->
        <!-- {{ districts }} -->

        <!--<r-checkbox class="mt-2" label="Границы районов" v-model="isShowAreaBoundaries"/> -->
        <r-input
          @blur="searchAddress"
          class="mt-4"
          label="Адрес"
          v-model="address"
          @onPressEnter="searchAddress"
          :readonly="addressLoading"
        />
        <r-checkbox
          class="mt-4"
          v-model="showRegions"
          title="Показать участки КУМИ"
          @input="changeShowRegions"
        />
      </div>
    </div>
    <r-button-action
      class="mt-10 mb-15"
      title="Удалить организацию"
      icon="delete"
      color="fargo"
      @click="DeleteItem($route.query.id)"
      v-if="isEditPage"
    />
    <div class="organization-form__btn-cont mt-6">
      <r-button
        @click="submit"
        :disabled="isSave"
        :is-loading="isLoading"
        width="wide"
        :title="isEditPage ? 'Сохранить' : 'Добавить'"
      />
      <!-- <r-button
        @click="save"
        width="wide"
        v-if="isReverseCoords"
        title="Пересохранить координаты"
      />-->
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { LLayerGroup, LMarker, LPolygon, LIcon, LPopup, LGeoJson, LTooltip } from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import { EditablePolygon } from 'vue2-leaflet-editable';
import { loadYmap } from '@/plugins/vue-yandex-maps/vue-yandex-maps.umd';
import ContactItems from '@/components/ContactItems.vue';
import ImgUploader from '../components/ImgUploader.vue';
import RirMap from '@/components/RirMap.vue';
import AdminHeader from '@/components/AdminHeader.vue';
import Api from '../api/Api';
import ModalCrop from '@/components/ModalCrop';
import ModalViewImage from '@/components/ModalViewImage';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';
import rirMapLeaflet from '../components/RirMapLeaflet';
import Loader from '../components/Loader';

export default {
  components: {
    LLayerGroup,
    LMarker,
    LPolygon,
    LIcon,
    LPopup,
    LGeoJson,
    LTooltip,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    EditablePolygon,
    ContactItems,
    ImgUploader,
    RirMap,
    AdminHeader,
    rirMapLeaflet,
    Loader
  },
  data() {
    return {
      timeout: null,
      isShowAreaBoundaries: true,
      blockHouseCount: null,
      selectedDocuments: [],
      coordinates: [0, 0],
      mapCenter: [],
      geometry: [],
      description: null,
      workHours: null,
      drawTarget: null,
      yaMapPoint: null,
      yaMapPointEdge: null,
      mapInstance: null,
      newPolygonIndex: null,
      isShowMap: false,
      isLoadYmap: false,
      // ---
      address: null,
      document: {},
      phone: null,
      phoneList: [],
      mail: null,
      mailList: [],
      web: null,
      name: null,
      houseCount: null,
      imageUrl: null,
      loadedImg: {},
      isLoading: false,
      isSave: true,
      addressLoading: false,
      initialData: [],
      organization: [],
      searchCoordinates: [],
      buildings: [],
      searchText: null,
      showRegions: false,
      loadData: false,
      editPolygon: false
    };
  },
  computed: {
    options() {
      return {
        // onEachFeature: this.onEachFeatureFunction
      };
    },
    onEachFeatureFunction() {
      return (feature, layer) => {
        layer.bindTooltip(
          `<div class='sulguni'>${
            feature.properties.cadastralIdentifier
          }</div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    isReverseCoords() {
      return this.$route.query.SaveReverveCoords;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        const _this = this;
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
          this.onCoords(val);
        }, 420);
      }
    },
    // mapKey() {
    //    return this.$route.query.id || new Date().getTime();
    // },
    districts() {
      const districts = [];
      this.$store.state.districts.forEach(el => {
        const district = {
          id: el.properties.id,
          title: el.properties.title
        };

        if (el.geometry.type === 'Polygon') {
          district.geometry = [el.geometry.coordinates];
        } else {
          district.geometry = el.geometry.coordinates;
        }

        districts.push(district);
      });
      return districts;
    },
    documentList() {
      const documents = this.$store.state?.documents;// ?.map(doc => ({ id: doc.id, value: doc.title }));
      if (this.selectedDocuments.length) {
        const selectedIds = this.selectedDocuments.map(el => el.id);
        return documents.filter(doc => !selectedIds.includes(doc.id));
      }
      return documents;
    },
    filteredObjects() {
      let objects = [...this.$store.state.organizations];

      objects.sort((x, y) => ((x.active === y.active) ? 0 : x.active ? -1 : 1));
      objects = objects.filter(el => +el.id !== +this.$route.query.id);
      return {
        type: 'FeatureCollection',
        features: [
          ...objects.map(el => ({
            type: 'Feature',
            properties: {},
            geometry: {
              coordinates: el?.geometry?.coordinates || [],
              type: el?.geometry?.type || 'Polygon'
            }
          }))
        ]
      };
    },
    isEditPage() {
      return this.$route.query.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },

  async mounted() {
    await this.$store.dispatch('getDistricts');
    if (!this.documentList.length) {
      await this.$store.dispatch('getDocuments');
    }
    if (!this.$store.state.organizations?.length) {
      await this.$store.dispatch('getOrganizations');
    }
    /*
    await loadYmap({lang: 'ru_RU', coordorder: 'latlong'});
    // console.log('ymaps', ymaps); // здесь доступен весь функционал ymap
    this.yaMapPoint = window.ymaps.templateLayoutFactory.createClass(
      '<div class=\'ya-map-point\'></div>'
    );
    this.yaMapPointEdge = window.ymaps.templateLayoutFactory.createClass(
      '<div class=\'ya-map-point--edge\'></div>'
    );
    this.isLoadYmap = true; */
  },

  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData(this.$route.query.id);
    this.isShowMap = true;
  },
  deactivated() {
    this.resetData();
    this.isShowMap = false;
  },
  methods: {

    getRegionCoords(region) {
      return region?.wgs?.coordinates || [];
    },
    showZoneOptions(pol) {
      console.log('sssssssssss');
    },
    async changeShowRegions(el) {
      if (el) {
        if (!this.$store.state.regions?.length) {
          await this.$store.dispatch('getRegions');
        }
      }
    },
    onCoords: _.debounce(function (address) {
      if (address) {
        axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.$cityName} ${address.trim()}&format=json&lang=ru_RU&kind=house`)
          .then(result => {
            const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;
            if (geo) {
              const coordinates = geo.Point?.pos?.split(' ').reverse();
              this.searchCoordinates = { id: 'searchMarker', coordinates: coordinates.reverse() };
              // this.mapCenter = this.coordinates
              this.mapInstance.setBounds(window.ymaps.util.bounds.fromPoints([this.searchCoordinates.coordinates]));
              this.mapInstance.setZoom(18, { duration: 100 });
            }
          });
      }
    }, 100),
    submit() {
      if (this.isEditPage) {
        this.OpenSaveModal();
      } else {
        this.save();
      }
    },
    async OpenSaveModal() {
      await this.$rir.modal.open(SaveModal, {
        saveFunc: this.save,
        title: this.name,
        type: 'organization'
      });
    },
    async DeleteItem(id) {
      await this.$rir.modal.open(DeleteModal, {
        id: Number(id),
        typeAction: 'deleteOrganization',
        name: this.name
      });
    },
    isChange(field) {
      if (this.organization.length > 0) {
        if (this.organization[field] === this[field]) {
          this.isSave = true;
        } else {
          this.isSave = false;
        }
      } else {
        this.isSave = false;
      }
    },
    onMapInit(e) {
      this.mapInstance = e;
    },
    deletePolygon(e) {
      e.relatedTarget.remove();
      this.isChange('geometry');
    },
    markersWasAdd(e) {
      //* После добавления объекта на карту, проверяем соответствует ли index текущего с index'ом последнего
      // добавленного

      if (this.newPolygonIndex === null) return;
      const res = e.find(
        obj => obj.properties._data.markerId === `${this.newPolygonIndex}_territory`
        // obj => obj.properties._data.markerId === `_territory`
      );
      if (res) {
        res.editor.startDrawing();
        this.drawTarget = res;
      }
    },
    onPolygonClick(e) {
      this.drawTarget = e.originalEvent.target;
      e.originalEvent.target.editor.startEditing();
    },
    onPolygonDblClick(e) {
      // this.drawTarget = e.originalEvent.target;
      // e.originalEvent.target.editor.startEditing();
    },
    polygonDrawingEnd(polygon) {
      this.isChange('geometry');
    },
    geometryChange(e) {
      console.log(e);
      const index = e.originalEvent.target.properties._data.markerId.split('_')[0];
      const markerGeometry = e.originalEvent.target.geometry.getCoordinates();

      // console.log('geoCoords', e.getPixelGeometry());
      const geometryArr = Array.from(markerGeometry);
      if (geometryArr[geometryArr.length - 1].length !== 0) {
        // this.$set(this.geometry, index, geometryArr);
      }

      // const geoCoords = e.originalEvent.target.geometry.getCoordinates();
      // this.geometry2 = geoCoords;
      this.drawTarget = e.originalEvent.target;
      this.isSave = false;
    },

    addDocument() {
      if (this.document?.id) {
        this.selectedDocuments.push(this.document);
        this.document = {};
      }
    },
    // onData(e) {
    //   console.log('dddata', e);
    // },
    // markersWasAdd(objects) {
    //   console.log('markersWasAdd', objects);
    // },
    // markersWasChange(objects) {
    //   console.log('markersWasChange', objects);
    // },

    async searchAddress(address) {
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.address;
      }

      this.addressLoading = true;
      if (data) {
        const res = await api.getAddress(JSON.stringify(data));

        if (isFindAddress && res?.address) {
          if (res?.addressFull?.street && res?.addressFull?.house) {
            this.address = `${res.addressFull.street} ${res.addressFull.house}`;
          } else {
            this.address = res.address;
          }
          this.isChange('address');
        } else if (!isFindAddress && res?.lat && res?.lng) {
          this.coordinates = [res.lat, res.lng];
          this.mapInstance.setCenter(this.coordinates, 14);
          this.isChange('coordinates');
        }
      }
      this.addressLoading = false;
    },
    onViewImage() {
      this.$rir.modal.open(ModalViewImage, {
        file: `/cityProperty/rest/${this.imageUrl}`
      });
    },
    deleteFile() {
      this.imageUrl = null;
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$rir.modal.open(ModalCrop, {
        image: this.loadedImg,
        valName: target === 'staff' ? 'contactImg' : 'imageUrl',
        portrait: target === 'staff'
      });
      this.isChange('loadedImg');
    },
    // onImgUpload(file, target = null) {
    //   const formData = new FormData();
    //
    //   formData.append('file', file); // for java back
    //
    //   // Загрузка файла
    //   // Don't set contentType manually → https://github.com/github/fetch/issues/505#issuecomment-293064470
    //   const API_URL = '/cityProperty/rest/service/company/img';
    //   fetch(API_URL, { method: 'POST', body: formData })
    //     .then(res => res.json())
    //     .then(res => {
    //       console.log(res);
    //       // JAVA back
    //       if (res.created) {
    //         this.imageUrl = res.id;
    //       }
    //     });
    // },

    addPhone() {
      this.phoneList.push(this.phone);
      this.phone = null;
      this.isChange('phoneList');
    },
    addMail() {
      this.mailList.push(this.mail);
      this.mail = null;
      this.isChange('mailList');
    },
    async save() {
      this.isLoading = true;
      const geometry = [];
      /* this.mapInstance.geoObjects.each(collection => {
        console.log(collection);
        if (collection?.properties) {
          collection.each(geoObject => {
            // если в markerId есть строка marker, значит - маркер, а не полигон
            if (geoObject?.properties?._data?.markerId.indexOf('territory') !== -1) {
              const geoCords = geoObject.geometry.getCoordinates();
              geoCords.flat(5).length >= 8 && geometry.push(geoObject.geometry.getCoordinates());
            }
          });
        }
      }); */
      // save to longlat
      this.coordinates = this.coordinates.reverse();

      this.mapInstance.mapObject.eachLayer(layer => {
        if (layer.options.name === 'polygon') {
          geometry.push(layer._latlngs.map(el => el.map(coord => [coord.lng, coord.lat]))[0]);

        }
      });

      // if (this.isReverseCoords) {
      //   this.coordinates = this.coordinates.reverse();
      // }

      const data = {
        title: this.name,
        houseCount: +this.houseCount,
        blockHouseCount: +this.blockHouseCount,
        description: this.description,
        img: this.imageUrl,
        address: this.address,
        workHours: this.workHours,
        web: this.web,
        phones: this.phoneList,
        emails: this.mailList,
        coordinates: { coordinates: this.coordinates, type: 'Point' },
        geometry: null,
        // Передаем только id, бэк сам генерит остальные поля объекта по id
        documents: this.selectedDocuments.map(el => ({ id: el.id }))
      };
      // удалить св-во geometry если массив пустой
      if (geometry.length !== 0) {
        data.geometry = { coordinates: [geometry], type: 'MultiPolygon' };
      }


      if (!this.isEditPage) {
        const res = await this.$store.dispatch('setOrganization', data); //   ответ: {created: true, id: 4}
        // if (res?.created) {
        //   this.$store.dispatch('getOrganizations');
        // }
        // this.$router.push({ name: 'admin' });
      } else {
        data.id = this.$route.query.id;
        const res = await this.$store.dispatch('editOrganization', data); // ответ: {updated: true}
        // if (res?.updated) {
        //   this.$store.dispatch('getOrganizations');
        //   this.$router.push({ name: 'admin' });
        // }

      }
      // this.$store.dispatch('getOrganizations');
      // this.$router.push({ name: 'admin' });

      const r = this.$router.resolve({
        name: 'admin'
      });
      window.location.assign(r.href);
      this.isLoading = false;
    },
    onDragMarker(e) {
      this.coordinates = [e.lat, e.lng];
      this.searchAddress(this.coordinates);
    },
    onMapClick(e) {
      if (!this.coordinates.length) {
        this.coordinates = [e.latlng.lat, e.latlng.lng];
        this.searchAddress(this.coordinates);
      }

      //   if (!this.isEditPage) {
      //     this.coordinates = coords;
      //   }
    },
    onMapDblClick(coords) {
      // if (this.isShowAreaBoundaries) return; // показать границы

      // Добавляем в массив полигонов массив с координатами первой точкой нового полигона
      this.geometry.push([[coords]]);

      // Получаем index элемента нового полигона
      this.newPolygonIndex = this.geometry.length - 1;
    },
    onMapRightClick(e) {
      //  this.mapInstance.geoObjects.removeAll()
      //       this.mapInstance.geoObjects.each(function(collection) {
      //           collection.each(function(geoObject) {
      //   collection.remove(geoObject)
      // });
      // });

      this.drawTarget && this.drawTarget.editor.stopEditing();
      // this.drawTarget && this.drawTarget.editor.startEditing();
      // this.drawTarget && this.drawTarget.editor.startDrawing();
    },
    resetData() {
      this.imageUrl = null;
      this.name = null;
      this.houseCount = null;
      this.blockHouseCount = null;
      this.description = null;
      this.workHours = null;
      this.web = null;
      this.phone = null;
      this.phoneList = [];
      this.mail = null;
      this.mailList = [];
      this.document = {};
      this.selectedDocuments = [];
      this.isShowAreaBoundaries = true;
      this.address = null;
      this.loadedImg = {};
      this.isLoading = false;
      this.mapCenter = this.$cityCenter;
      this.coordinates = [];
      this.geometry = [];
      this.buildings = [];
      this.newPolygonIndex = null;
    },
    async setData(id) {
      this.loadData = true;
      // this.resetData();
      const org = await new Api().getOrganizationById(id);// this.$store.getters.getOrganizationById(id);
      await new Api().getBuildings(this.$route.query.id).then(res => {
        this.buildings = res;
      });
      this.address = org?.address || null;
      this.phoneList = org?.phones || [];
      this.mailList = org?.emails || [];
      this.web = org?.web || null;
      this.description = org.description || null;
      this.workHours = org.workHours || null;
      this.name = org.title;
      this.houseCount = String(org.houseCount) || null;
      this.blockHouseCount = String(org.blockHouseCount) || null;
      this.imageUrl = org.img || null;
      this.loadedImg = org.img || null;
      this.selectedDocuments = org.documents.map(el => ({ id: el.id, value: el.title }));
      this.geometry = org?.geometry?.coordinates || [];
      this.coordinates = org?.coordinates?.coordinates || [0, 0];
      this.organization = JSON.parse(JSON.stringify(org));

      // reverse to latlong
      this.coordinates = this.coordinates.reverse();
      this.geometry = this.geometry.map(el => el.map(el2 => el2.map(el3 => el3.reverse())));
      this.loadData = false;
    }
  }

  // watch: {
  //   $data: {
  //     handler: function(val, oldVal) {
  //       console.log(val, oldVal);
  //     },
  //     deep: true
  //   }
  // }
  // beforeRouteEnter(to, from, next) {
  //   console.log('to from', to, from);
  //   if (to.query.id) {
  //     next(vm => {
  //       vm.setData(to.query.id);
  //     });
  //   }
  //   // else {
  //   //   next(vm => vm.resetData());
  //   // }
  // }
};
</script>

<style lang="scss" scoped>
.organization-form {
  min-height: 100vh;
  padding-bottom: 130px;
}
.organization-form__img-cont {
  border-radius: 8px;
}
.organization-form__btn-cont {
  position: fixed;
  right: 0;
  bottom: 0;
  width: calc(100% - 25.3%);
  background-color: #f6f9fe;
  z-index: 2;
  padding: 32px 40px 40px 32px;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
}
.delete-btn {
  opacity: 0.72;
}
.delete-btn:hover {
  cursor: pointer;
  opacity: 1;
}
::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}
::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}
</style>
