import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import VueMask from 'v-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import rir from './plugins/RirLib';
import YmapPlugin from './plugins/vue-yandex-maps/vue-yandex-maps.umd';

import { loadSvg, getStorageUrl } from '@/plugins/LoadIcons';
Vue.use(VueMask);
const mapSettings = {
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};

Vue.prototype.$storage = window?.__CONFIG__?.storage || null;
Vue.prototype.$cityName = window?.__CONFIG__?.cityName || '';
Vue.prototype.$cityCenter = Array.isArray(window?.__CONFIG__?.cityCenter?.latLng)
  ? [...window?.__CONFIG__?.cityCenter?.latLng]
  : process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);

const S3_COMMON = window?.__CONFIG__?.storage
  ? `${window.__CONFIG__.storage}/common/vue`
  : process.env.VUE_APP_S3_COMMON_PATH;

const S3_COMMON_ALL = window?.__CONFIG__?.storage
  ? `${window.__CONFIG__.storage}/common`
  : process.env.VUE_APP_S3_COMMON_PATH_ALL;

Vue.prototype.$S3_COMMON = S3_COMMON;

Vue.prototype.$markerIcon = (iconName = 'marker') => ({
  layout: 'default#image',
  imageHref: `${S3_COMMON}/icons/map/${iconName}.svg`
});
Vue.prototype.$markerHomeIcon = () => ({
  layout: 'default#image',
  imageHref: 'data:image/svg+xml;charset=UTF-8,%3csvg width=\'24\' height=\'36\' viewBox=\'0 0 24 36\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C12.9385 24 12.1059 27.0311 11.2721 30.0663C10.4391 33.0986 9.5 36 10.5352 36C12.7597 36 24 22.8279 24 12Z\' fill=\'%233D75E4\'/%3e%3cg clip-path=\'url(%23clip0_1_15)\'%3e%3cpath d=\'M10.7644 3.42735C11.4896 2.85755 12.5104 2.85755 13.2356 3.42735L19.6178 8.44181C20.052 8.78302 20.1275 9.41168 19.7863 9.84595C19.4451 10.2802 18.8164 10.3557 18.3822 10.0145L12 5L5.61785 10.0145C5.18357 10.3557 4.55492 10.2802 4.21372 9.84595C3.87251 9.41168 3.94795 8.78302 4.38223 8.44181L10.7644 3.42735Z\' fill=\'white\'/%3e%3cpath d=\'M6.49999 11C7.05228 11 7.49999 11.4477 7.49999 12V17H16.4999V12C16.4999 11.4477 16.9477 11 17.4999 11C18.0522 11 18.4999 11.4477 18.4999 12V17C18.4999 18.1046 17.6045 19 16.4999 19H7.49999C6.39543 19 5.5 18.1046 5.5 17V12C5.5 11.4477 5.94771 11 6.49999 11Z\' fill=\'white\'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id=\'clip0_1_15\'%3e%3crect width=\'16\' height=\'16\' fill=\'white\' transform=\'translate(4 3)\'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e '
});

Vue.prototype.$markerIconGrey = (iconName = 'marker') => ({
  layout: 'default#image',
  imageHref: `${S3_COMMON}/icons/map/${iconName}.svg`,
  imageSize: [0, 0],
  imageOffset: [0, 0],
  contentOffset: [0, 0]
});

Vue.prototype.$markerIconAll = (icon = 'background:url(/img/types/index.php?fab=1) no-repeat;background-position:center;background-size:100%') =>
  // const template =  window.ymaps.templateLayoutFactory.createClass('<div class="placemark_layout_container"><div class="circle_layout">#</div></div>')
  // const icon = iconName.split('_')
  // console.log(store.state, icon, store.state.icons[iconName])
  ({
    layout: 'default#imageWithContent',
    imageHref: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='36px' viewBox='0 0 24 36' %3E%3Cscript xmlns=''/%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='marker.e50867a'%3E%3Cpath d='M12,0 C18.62745,0 24,5.37255 24,12 C24,18.0768 21.377925,22.230675 19.402275,25.36065 C19.264725,25.5786 19.13025,25.791675 18.999975,26.000025 C17.2179,28.851375 12.467025,34.56105 11.45295,35.771325 C11.329125,35.919075 11.149575,36 10.956825,36 C10.4964,36 10.18035,35.535675 10.3395,35.10375 C13.399875,26.799675 12,24 12,24 C5.37255,24 0,18.62745 0,12 C0,5.37255 5.37255,0 12,0 Z M12,18 C15.313725,18 18,15.313725 18,12 C18,8.686275 15.313725,6 12,6 C8.686275,6 6,8.686275 6,12 C6,15.313725 8.686275,18 12,18 Z' id='Shape' fill='%23D06E0B'/%3E%3Ccircle id='Oval' fill='%23FFFFFF' fill-rule='nonzero' cx='12' cy='12' r='6'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
    imageSize: [24, 36],
    imageOffset: [0, 0],
    contentOffset: [4, 3],
    contentLayout: `<div style="width: 14px; height: 14px; background: ${icon}"></div>`
  })
// layout: template,
// iconShape: {
//   type: 'Rectangle',
//   // Прямоугольник описывается в виде двух точек - верхней левой и нижней правой.
//   coordinates: [
//     [-25, -25], [25, 25]
//   ]
// }
// imageHref: `${S3_COMMON_ALL}/icons/${icon[1]}.svg`,
// contentLayout: template
// }
;

Vue.use(YmapPlugin, mapSettings);

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions
          // for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        }
      });
    },
    router,
    store,
    $rir: rir
  }
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
