<template>
  <r-popover
    v-model="activePicker"
    position="right-bottom"
    @close="dropModel"
  >
    <template #activator>
      <r-button-action
        v-model="activePicker"
        is-arrow
        title="Период"
      />
    </template>
    <template #content>
      <div
        class="pa-4"
        id="picker"
      >
        <r-date-picker
          v-model="model"
          period
          inline
          @input="checkInputDate"
        >
          <template #header>
            <r-select
              label="Период"
              :items="itemsPeriod"
              v-model="periodModel"
              @change="setModel"
            />
          </template>
          <template #footer>
            <r-input
              :is-clear="false"
              class="mb-5 picker-input"
              label="Дата от и до"
              :value="getValueInput"
              @clearModel="model = null"
              readonly
            />
            <r-button
              title="Выбрать"
              width="wide"
              @click="sendEmit"
              :disabled="disabledButton"
            />
          </template>
        </r-date-picker>
      </div>
    </template>
  </r-popover>
</template>

<script>

export default {
  name: 'DatePickerAllSlots',
  data() {
    return {
      activePicker: false,
      model: null,
      periodModel: null,
      itemsPeriod: [
        {
          id: 'full',
          title: 'За все время'
        },
        {
          id: 'now',
          title: 'Сегодня'
        },
        {
          id: 'week',
          title: 'Текущая неделя'
        },
        {
          id: 'month',
          title: 'Текущий месяц'
        }
      ]
    };
  },
  computed: {
    disabledButton() {
      return !this.model || this.model.length < 1;
    },
    getValueInput() {
      if (this.model) {
        if (!this.model[1]) {
          return `${this.toLocaleUTCDateString(this.model[0], 'ru-Ru')}–__.__.____`;
        }
        return `${this.toLocaleUTCDateString(this.model[0], 'ru-Ru')}–${this.toLocaleUTCDateString(this.model[1], 'ru-Ru')}`;
      }
      return '__.__.____–__.__.____';
    }
  },
  methods: {
    checkInputDate() {
      this.periodModel = null;
    },
    toLocaleUTCDateString(date, locales, options = {}) {
      let d = date;
      if (date) {
        // eslint-disable-next-line prefer-regex-literals
        const regexp = new RegExp('(\\d{4}-\\d{2}-\\d{2})', 'gm');
        d = date.match(regexp);
      }
      const adjustedDate = new Date(d);
      return adjustedDate.toLocaleDateString(locales, options);
    },
    setModel(val) {
      let firstday = null;
      let lastday = null;
      switch (val) {
        case 'now':
          this.model = [new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')];
          break;
        case 'week':
          // eslint-disable-next-line no-case-declarations
          const curr = new Date();
          // eslint-disable-next-line no-case-declarations
          const first = curr.getDate() - curr.getDay() + 1;
          // eslint-disable-next-line no-case-declarations
          const last = first + 6;

          firstday = new Date(curr.setDate(first)).toLocaleDateString('en-CA');
          lastday = new Date(curr.setDate(last)).toLocaleDateString('en-CA');
          this.model = [firstday, lastday];
          break;
        case 'month':
          firstday = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            .toLocaleDateString('en-CA');
          lastday = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
            .toLocaleDateString('en-CA');
          this.model = [firstday, lastday];
          break;
        default:
          firstday = new Date(new Date().getFullYear(), 0, 1)
            .toLocaleDateString('en-CA');
          lastday = new Date(new Date().getFullYear() + 1, 0, 0)
            .toLocaleDateString('en-CA');
          this.model = [firstday, lastday];
      }
    },
    sendEmit() {
      this.activePicker = false;
      this.$emit('input', this.model);
    },
    dropModel() {
      this.model = null;
      this.periodModel = null;
    }
  }
};
</script>

<style lang="scss">
.picker-input{
  &.RInput{
    opacity: 1;
  }
  & .RInput__clear.events {
    cursor: pointer;
    pointer-events: auto;
  }
}
</style>
