import ApiService from './ApiService';

export default class Api extends ApiService {
  async getOrganizations(payload) {
    const { data } = await this._axios.get('cityProperty/rest/service/company');
    return data;
  }

  async getDocuments() {
    const { data } = await this._axios.get('cityProperty/rest/service/company/document ');
    return data;
  }

  async getDistricts() {
    const { data } = await this._axios.get('cityProperty/rest/service/company/all/district');
    return data;
  }

  async editOrganization(payload) {
    const { data } = await this._axios.put('cityProperty/rest/service/company', payload);
    return data;
  }

  async deleteOrganization(id) {
    const { data } = await this._axios.delete(`cityProperty/rest/service/company/${id}`);
    return data;
  }

  async deleteDocument(id) {
    const { data } = await this._axios.delete(`cityProperty/rest/service/company/document/${id}`);
    return data;
  }

  async setDocument(payload) {
    const { data } = await this._axios.post('cityProperty/rest/service/company/document', payload);
    return data;
  }

  async editDocument(payload) {
    const { data } = await this._axios.put('cityProperty/rest/service/company/document', payload);
    return data;
  }

  async setOrganization(payload) {
    const { data } = await this._axios.post('cityProperty/rest/service/company', payload);
    return data;
  }

  //   тогда да
  // POST action=getExtraProblems , джейсон как на скриншоте
  // формат даты YYYY-MM-DD
  // типы проблем наборные как видишь или все подряд
  // а casse: -1 маркеры проблем, -2 теплокарты, -3 маркеры решений
  // async getProblems(payload) {
  //   const { data } = await this._axios.post('ajax.php?action=getExtraProblems', {
  //     casse: -2,
  //     currentMainMenuNumber: 10
  //   });
  //   return data;
  // }

  async getProblems(payload) {
    // &v=2&page=0&pageSize=50&wPhotos=1&json={%22sort%22:0,%22filterTypes%22:%228%22
    // const { data } = await this._axios.post('https://test.rosatom.city/ajax.php?action=getAll', {
    const { data } = await this._axios.post('ajax.php?action=getAll', {
      ...payload,
      v: 2,
      page: 0,
      pageSize: 500,
      wPhotos: 1
      // json: { sort: 0, filterTypes: '8' }
    });
    return data;
  }

  async getProblemTypes() {
    const { data } = await this._axios.get('/ajax.php?action=getAllProblemTypes&asArray=1');
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('proxy/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getUserInfo() {
    const { data } = await this._axios.post('nginxApi.php?get=user');
    return data;
  }

  async logOut() {
    const { data } = await this._axios.get('auth/rest/logout');
    return data;
  }

  async getRegions() {
    // получить список участков
    const { data } = await this._axios.get('/cityProperty/rest/kumi/plot?rearrangeLongLat=true');//?rearrangeLongLat=true
    return data;
  }

  async getBuildings(id) {
    // получить список домов компании
    const { data } = await this._axios.get(`/cityProperty/rest/service/company/${id}/buildings`);
    return data;
  }

  async getOrganizationById(id) {
    const { data } = await this._axios.get(`/cityProperty/rest/service/company/${id}`);
    return data;
  }
}
