<template>
  <r-popover
    class="relative"
    v-model="activePicker"
  >
    <template #activator>
      <r-button-action
        v-model="activePicker"
        is-arrow
        :title="selected.title"
      />
    </template>
    <template #content>
      <div class="list">
        <button
          type="button"
          :class="['item', { 'item--active': item.active }]"
          @click.native="!item.disabled && selectItem(item)"
          v-for="item in items"
          :key="item.id"
          @click="select(item)"
        >
          <r-icon
            size="16"
            fill="rocky"
            v-if="item.icon"
            class="mr-3"
            :icon="item.icon"
          />
          <span class="sulguni">{{ item.title }}</span>
        </button>
      </div>
    </template>
  </r-popover>
</template>

<script>
export default {
  name: 'CheckboxDropdown',
  props: {
    items: {
      tyep: Array,
      required: true
    }
  },
  data() {
    return {
      activePicker: false
    };
  },
  computed: {
    selected() {
      return this.items.filter(el => el.active)?.[0] || null;
    }
  },

  methods: {
    select(item) {
      this.activePicker = false;
      this.$emit('select', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
// .disabled {
//   opacity: 0.72;
// }
.list {
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  opacity: 0.72;
  padding: 14px 16px;
  position: relative;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
.item--active {
  opacity: 1;
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-left: 4px solid var(--r-rocky);
  }
}
</style>
