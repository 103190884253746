<template>
  <div class="doc-form">
    <admin-header :title="!isEditPage ? 'Новый документ' : 'Редактирование документа'">
      <sup
        class="text-c13 color-fargo"
        v-if="!isSave"
        v-show="isEditPage"
      >Не сохранено</sup>
    </admin-header>
    <p class="doc-form__date feta opacity-48 mt-2">
      {{ getDate }}
    </p>

    <div class="mt-8 doc-form__grid">
      <div>
        <r-input
          label="Название"
          v-model="title"
          @blur="isChange('title')"
        />

        <text-editor
          class="mt-8"
          v-model="description"
          @input="isChange('description')"
        />
      </div>
      <div>
        <upload-file
          sub-title="перетяните сюда файлы с расширением .doc или .pdf"
          :accept-file="['.doc', '.docx', '.pdf', '.xls', '.xlsx']"
          :count-file="5"
          upload-url="/cityProperty/rest/service/company/document/file"
          :value="files"
          @input="onChangeFiles"
          :params-body="{ 'Content-Type': 'multipart/form-data' }"
          :count-line="1"
        />
      </div>
    </div>
    <r-button-action
      class="mt-10 mb-15"
      title="Удалить документ"
      icon="delete"
      color="fargo"
      @click="DeleteItem($route.query.id)"
      v-if="isEditPage"
    />

    <div class="doc-form__btn-cont mt-6 flex">
      <r-button
        @click="submit"
        :disabled="isLoading || isSave"
        width="wide"
        :title="isEditPage ? 'Сохранить' : 'Добавить'"
      />
      <r-button
        class="ml-6"
        color="secondary"
        @click="preview"
        width="wide"
        title="Предварительный просмотр"
      />
    </div>

    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import AdminHeader from '@/components/AdminHeader.vue';
import ModalPreview from '@/components/ModalPreview.vue';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';
import UploadFile from '../components/UploadFile';

export default {
  name: 'DocForm',
  components: {
    AdminHeader,
    TextEditor,
    ModalPreview,
    UploadFile
  },

  beforeRouteEnter(to, from, next) {
    // console.log('to from', to, from);
    if (to.query.id) {
      next(vm => {
        vm.setData(to.query.id);
      });
    } else {
      next(vm => vm.resetData());
    }
  },

  data() {
    return {
      isLoading: false,
      title: null,
      editor: null,
      description: null,
      files: [],
      initialData: {},
      isSave: true
    };
  },

  computed: {
    isEditPage() {
      return this.$route.query.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    },
    getDate() {
      return new Date().toLocaleString('Ru-ru').split(',')[0];
    }
  },

  methods: {
    submit() {
      if (this.isEditPage) {
        this.OpenSaveModal();
      } else {
        this.save();
      }
    },
    async OpenSaveModal() {
      await this.$rir.modal.open(SaveModal, {
        saveFunc: this.save,
        title: this.title,
        type: 'documents'
      });
    },
    async DeleteItem(id) {
      await this.$rir.modal.open(DeleteModal, {
        id: Number(id),
        typeAction: 'deleteDocument',
        name: this.title
      });
    },
    isChange(field) {
      if (this.initialData[field] === this[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    async preview() {
      await this.$rir.modal.open(ModalPreview, {
        title: this.title,
        content: this.description,
        files: this.files
      });
    },
    async save() {
      //   const data = {
      //     id: this.$route.query.id || -1, // -1 если новый документ
      //     item: {
      //       title: this.title,
      //       description: this.description,
      //       layerId: 10, //id слоя(модуля)
      //       filenames: this.files.map(el => ({
      //         contentLength: el.contentLength,
      //         filename: el.filename,
      //         url: el.url
      //       }))
      //     }
      //   };

      const data = {
        // active: true,
        title: this.title,
        description: this.description,
        files: this.files.map(file => ({ id: file.id, title: file.filename }))
      };

      if (!this.isEditPage) {
        const res = await this.$store.dispatch('setDocument', data); //   ответ: {created: true, id: 4}
        if (res?.created) {
          this.$store.dispatch('getDocuments');
          // this.$router.push({ name: 'admin' });
          const r = this.$router.resolve({
            name: 'admin'
          });
          window.location.assign(r.href);
        }
      } else {
        data.id = this.$route.query.id;
        const res = await this.$store.dispatch('editDocument', data); // ответ: {updated: true}
        if (res?.updated) {
          this.$store.dispatch('getDocuments');
          // this.$router.push({ name: 'admin' });
          const r = this.$router.resolve({
            name: 'admin'
          });
          window.location.assign(r.href);
        }
      }
    },

    onChangeFiles(files) {
      // console.log('files', files);
      if (!files?.length) {
        this.files = [];
        this.isChange('files');
        return;
      }

      const formattedFiles = [];

      const fileList = files.map(el => ({
        id: el.id,
        filename: el.filename || el.name,
        url: `/cityProperty/rest/service/company/document/file/${el.id}`
      }));
      formattedFiles.push(...fileList);

      // for php backEnd
      //   files.forEach(el => {
      //     if (el?.files?.length) {
      //       const fileList = el.files.map(el => ({
      //         contentLength: Number(el.size),
      //         contentType: el.type,
      //         filename: el.originalName,
      //         url: `${el.url}`
      //       }));
      //       formattedFiles.push(...fileList);
      //     } else {
      //       formattedFiles.push(el);
      //     }
      //   });

      this.files = formattedFiles;
      this.isChange('files');
    },

    resetData() {
      this.title = null;
      this.description = null;
      this.files = [];
    },

    // Загрузка данных для редактирования
    async setData(id) {
      this.resetData();
      if (this.$store.state.documents?.length < 1) {
        await this.$store.dispatch('getDocuments');
      }
      const doc = this.$store.getters.getDocumentById(id);
      console.log('DocumentById', doc);
      this.title = doc.title;
      this.description = doc.description;
      this.files = doc.files.map(el => ({
        filename: el.title,
        id: el.id,
        icon: 'attach',
        url: `/cityProperty/rest/service/company/document/file/${el.id}`
      }));
      this.initialData = {
        title: doc.title,
        description: doc.description,
        files: doc.files
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.doc-form {
  min-height: 100vh;
  padding-bottom: 130px;
  &__grid {
    display: grid;
    grid-template-columns: 65% 35%;
    grid-gap: 24px;
  }
  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #f6f9fe;
    z-index: 2;
    padding: 32px 40px 40px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}
::v-deep {
  .rir-file-list__item {
    background-color: transparent;
  }
  .rir-file-list__info {
    opacity: 0.72;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
  .rir-file-list__details {
    padding: 0 8px;
  }
  .rir-file-list__img {
    height: auto;
    width: auto;
  }
  .rir-file-list__size {
    display: none;
  }
}
</style>
