<template>
  <div class="flex align-items-center">
    <div class="flex-1">
      <router-link
        v-if="back"
        class="flex align-items-center sulguni mb-7 color-rocky"
        :to="to"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="arrow-left"
        />Назад
      </router-link>
      <h1 class="ricotta">
        {{ title }} <slot />
      </h1>
    </div>
    <!-- <div class="flex align-items-center" @click="logOut">
      <img
        width="40px"
        style="border-radius: 50%; background-color: #F6F9FE;"
        :src="img"
        alt="Аватар пользователя"
      />
      <div class="ml-2 user-info">
        <p class="user-info__name roquefort">{{ user.fio }}</p>
        <p class="user-info__role сaprino opacity-72 mt-1">{{ user.appealOperatorLevel }}</p>
      </div>
    </div> -->
  </div>
</template>

<script>
// import Api from '@/api/Api.js';
export default {
  name: 'AdminHeader',
  props: {
    to: {
      type: Object,
      default: () => ({ name: 'admin' })
    },
    title: {
      type: String,
      required: true
    },
    // img: {
    //   type: String,
    //   default: '/upload/city36/medicine/filename1_6166cc71b28be.jpeg'
    // },
    back: {
      type: Boolean,
      default: true
    }
  }
  // data() {
  //   return {
  //     name: null,
  //     role: null
  //   };
  // },
  // computed: {
  //   user() {
  //     return this.$store.getters.getUser;
  //   }
  // },
  // methods: {
  //   async logOut() {
  //     const api = new Api();
  //     const res = await api.logOut();
  //     console.log('logOut', res);
  //     if (res.status === 1) {
  //       this.$store.commit('setUser', null);
  //       window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
// .user-info {
//   max-width: 132px;
//   white-space: nowrap;
//   &__name {
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
//   &__role {
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
// }
</style>
