var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editor)?_c('div',{staticClass:"flex align-items-center"},[_c('r-select',{attrs:{"items":_vm.textStyleList,"label":"Стиль","value":_vm.selectedStyle,"return-object":""},on:{"change":_vm.onChangeStyle}}),_c('div',{staticClass:"editor__buttons flex"},[_c('span',{staticClass:"editor__devider"}),_c('button',{staticClass:"editor__btn",class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleBold()
            .run()}}},[_c('r-icon',{attrs:{"size":"16","icon":"bold","fill":"interstellar"}})],1),_c('button',{staticClass:"editor__btn",class:{ 'is-active': _vm.editor.isActive('underline') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleUnderline()
            .run()}}},[_c('r-icon',{attrs:{"size":"16","icon":"underline","fill":"interstellar"}})],1),_c('button',{staticClass:"editor__btn",class:{ 'is-active': _vm.editor.isActive('strike') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleStrike()
            .run()}}},[_c('r-icon',{attrs:{"size":"16","icon":"cross","fill":"interstellar"}})],1),_c('span',{staticClass:"ml-3 editor__devider"}),_c('button',{staticClass:"editor__btn",class:{ 'is-active': _vm.editor.isActive('bulletList') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleBulletList()
            .run()}}},[_c('r-icon',{attrs:{"size":"16","icon":"bullet-list","fill":"interstellar"}})],1),_c('button',{staticClass:"editor__btn",class:{ 'is-active': _vm.editor.isActive('orderedList') },attrs:{"type":"button"},on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleOrderedList()
            .run()}}},[_c('r-icon',{attrs:{"size":"16","icon":"number-list","fill":"interstellar"}})],1),_c('span',{staticClass:"ml-3 editor__devider"}),_c('button',{staticClass:"editor__btn",class:{ 'is-active': _vm.editor.isActive('link') },attrs:{"type":"button"},on:{"click":function($event){_vm.setLink(_vm.editor.isActive('link'))}}},[_c('r-icon',{attrs:{"size":"16","icon":"link","fill":"interstellar"}})],1)])],1):_vm._e(),_c('editor-content',{staticClass:"mt-4",attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }