import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';
import { getStorageUrl, loadSvg } from '@/plugins/LoadIcons';

const api = new Api();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    icons: {},
    organizations: [],
    problems: [],
    problemTypes: [],
    documents: [],
    districts: [],
    isDocumentsLoading: true,
    isOrganizationsLoading: true,
    isProblemLoading: true,
    isDistrictsLoading: true,
    user: null,
    regions: {
      type: 'FeatureCollection',
      features: []
    }
  },
  mutations: {
    setRegions(state, res) {
      state.regions.features = res.map(el => ({
        type: 'Feature',
        geometry: el.wgs,
        properties: el
      }));
    },
    setOrganizations(state, orgs) {
      state.organizations = orgs;
    },
    deleteOrganization(state, orgId) {
      const indexOrg = state.organizations.findIndex(el => el.id === parseInt(orgId));
      console.log(orgId, indexOrg, state.organizations);
      state.organizations[indexOrg].active = false;
    },
    setProblems(state, problems) {
      state.problems = problems;
    },
    setProblemTypes(state, types) {
      state.problemTypes = types;
    },
    setDocuments(state, documents) {
      state.documents = !documents?.error ? documents : [];
    },
    setDistricts(state, districts) {
      state.districts = !districts?.error ? districts : [];
    },

    setDistrictsLoading(state, status) {
      state.isDistrictsLoading = status;
    },
    setDocsLoading(state, status) {
      state.isDocumentsLoading = status;
    },
    setOrgsLoading(state, status) {
      state.isOrganizationsLoading = status;
    },
    setProblemsLoading(state, status) {
      state.isProblemLoading = status;
    },
    setUser(state, user) {
      // console.log('SSSEEEET_USERR', user);
      state.user = user;
    },
    setIcons(state, { svg, name }) {
      // console.log('SSSEEEET_USERR', user);
      Vue.set(state.icons, name, svg);

    }
  },
  getters: {
    getDocumentById: state => id => state.documents.find(el => +el.id === +id),
    getLoading: state => state.isDocumentsLoading || state.isOrganizationsLoading,
    getUser: state => state.user || {},

    getOrganizationById: state => id => state.organizations.find(el => String(el.id) === String(id))
  },
  actions: {
    async getRegions(ctx) {
      const res = await api.getRegions();
      ctx.commit('setRegions', res);
      return res;
    },
    async getDistricts(ctx) {
      ctx.commit('setDistrictsLoading', true);
      const res = await api.getDistricts();
      // console.log('districts___', res);
      ctx.commit('setDistricts', res.features);
      ctx.commit('setDistrictsLoading', false);
    },
    async setOrganization(ctx, data) {
      const res = await api.setOrganization(data);
      // console.log('serOrganization', res);
    },

    async getProblems(ctx, data) {
      ctx.commit('setProblemsLoading', true);
      const res = await api.getProblems(data);
      const types = await api.getProblemTypes(data);
      (types?.all || []).forEach(type => {
        if (!ctx.state.icons[type.icon]) {
          loadSvg(getStorageUrl(type.icon))
            .then(svg => {
              ctx.commit('setIcons', { svg, name: type.icon });
            });
        }
      });

      // console.log('problems---------', res.all);
      ctx.commit('setProblemsLoading', false);
      ctx.commit('setProblems', res?.all || []);
      ctx.commit('setProblemTypes', types?.all || []);
    },

    async getOrganizations(ctx) {
      ctx.commit('setOrgsLoading', true);
      const res = await api.getOrganizations();
      // console.log('orgs', res);
      ctx.commit('setOrganizations', res);
      ctx.commit('setOrgsLoading', false);
    },

    async getDocuments(ctx) {
      ctx.commit('setDocsLoading', true);
      const res = await api.getDocuments();
      // console.log('getDocuments', res);
      // ctx.commit('setDocuments', res.all);
      ctx.commit('setDocuments', res);
      ctx.commit('setDocsLoading', false);
    },

    async setDocument({ dispatch }, data) {
      const res = await api.setDocument(data);
      // console.log('setDocument', res);
      return res;
    },

    async editDocument({ dispatch }, data) {
      const res = await api.editDocument(data);
      // console.log('editDocument', res);
      return res;
    },

    async editOrganization({ dispatch }, data) {
      const res = await api.editOrganization(data);
      // console.log('editDocument', res);
      return res;
    },

    async deleteOrganization(ctx, id) {
      const res = await api.deleteOrganization(id);
      // console.log('deleteOrg', res);
      ctx.commit('deleteOrganization', id);
      return res;
    },

    async deleteDocument(ctx, id) {
      const res = await api.deleteDocument(id);
      // console.log('deleteDoc', res);
      return res;
    },

    async getUserInfo(ctx) {
      const res = await api.getUserInfo();
      ctx.commit('setUser', res);
      // console.log('userInfo', res, ctx.state.user);
    }
  },
  modules: {}
});
