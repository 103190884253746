<template>
  <div>
    <div
      v-if="editor"
      class="flex align-items-center"
    >
      <r-select
        :items="textStyleList"
        label="Стиль"
        @change="onChangeStyle"
        :value="selectedStyle"
        return-object
      />

      <div class="editor__buttons flex">
        <span class="editor__devider" />
        <button
          type="button"
          class="editor__btn"
          @click="
            editor
              .chain()
              .focus()
              .toggleBold()
              .run()
          "
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <r-icon
            size="16"
            icon="bold"
            fill="interstellar"
          />
        </button>

        <button
          type="button"
          class="editor__btn"
          @click="
            editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          "
          :class="{ 'is-active': editor.isActive('underline') }"
        >
          <r-icon
            size="16"
            icon="underline"
            fill="interstellar"
          />
        </button>

        <button
          type="button"
          class="editor__btn"
          @click="
            editor
              .chain()
              .focus()
              .toggleStrike()
              .run()
          "
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <r-icon
            size="16"
            icon="cross"
            fill="interstellar"
          />
        </button>

        <span class="ml-3 editor__devider" />

        <button
          type="button"
          class="editor__btn"
          @click="
            editor
              .chain()
              .focus()
              .toggleBulletList()
              .run()
          "
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <r-icon
            size="16"
            icon="bullet-list"
            fill="interstellar"
          />
        </button>

        <button
          type="button"
          class="editor__btn"
          @click="
            editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run()
          "
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <r-icon
            size="16"
            icon="number-list"
            fill="interstellar"
          />
        </button>

        <span class="ml-3 editor__devider" />

        <button
          type="button"
          class="editor__btn"
          @click="setLink(editor.isActive('link'))"
          :class="{ 'is-active': editor.isActive('link') }"
        >
          <r-icon
            size="16"
            icon="link"
            fill="interstellar"
          />
        </button>
      </div>
    </div>

    <editor-content
      class="mt-4"
      :editor="editor"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';

export default {
  name: 'TextEditor',
  components: {
    EditorContent
  },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      editor: null,
      // textStyle: 1,
      textStyleList: [
        { id: 'paragraph', title: 'Текст' },
        { id: 'heading', title: 'Заголовок' }
      ]
    };
  },

  computed: {
    selectedStyle() {
      let style = 'paragraph';
      if (this.editor && this.editor.isActive('heading', { level: 2 })) {
        style = 'heading';
      }
      return this.textStyleList.filter(el => el.id === style)[0];
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false
        })
      ],
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML());

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      }
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  methods: {
    setLink() {
      // Убрать ссылку если она есть
      //   isLinked && this.editor.chain().focus().unsetLink().run();

      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL адрес:', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
    onChangeStyle(e) {
      const style = e.id;
      //   this.editor.commands.setTextSelection({ from: 31, to: 37 });
      //   this.$refs.editor.focus();
      //   this.editor.commands.focus('end');
      // this.editor.on('blur', ({ editor, event }) => {
      //   const { view, state } = editor;
      //   const { from, to } = view.state.selection;
      //   const text = state.doc.textBetween(from, to, '');
      //   this.selectedRange = { from, to };
      //   console.log(editor, event, from, to, text);
      // });

      if (style === 'paragraph') {
        this.editor
          .chain()
          .focus()
          .setParagraph()
          .run();
      } else {
        this.editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.editor__buttons {
  margin: 4px 0 4px 12px;
}
.editor__btn {
  align-items: center;
  display: flex;
  border-radius: 6px;
  margin-left: 12px;
  padding: 8px;
  &.is-active,
  &:hover {
    background-color: rgba(61, 117, 228, 0.08);
    cursor: pointer;
    > * {
      background-color: var(--r-rocky) !important;
    }
  }
}
.editor__devider {
  width: 1px;
  background-color: rgba(61, 117, 228, 0.08);
}

/* Basic editor styles */
::v-deep .ProseMirror {
  min-height: 456px;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: var(--rir-django);
  text-decoration-skip-ink: none;
  > * + * {
    margin-top: 0.75em;
  }

  & > * {
    cursor: text;
  }

  &:focus-visible {
    outline: none;
  }

  a {
    text-decoration: underline;
    color: var(--r-rocky);
    text-decoration-color: var(--r-rocky);
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
