<template>
  <div class="flex">
    <div class="upload">
      <div>
        <div class="parent align-items-center">
          <div class="container-upload">
            <r-icon
              icon="warning"
              fill="fargo"
              size="56"
            />
            <h1 class="ricotta mb-4 mt-7">
              Удалить
              {{ typeAction === 'deleteOrganization' ? 'организацию' : 'документ' }}?
            </h1>
            <div class="mb-8 font">
              Все данные
              {{ typeAction === 'deleteOrganization' ? 'об организации' : 'о документе' }} {{
                name
              }} будут удалены.
            </div>
            <div class="mt-2 buttons">
              <r-button
                class="flex-1"
                type="secondary"
                width="wide"
                title="Не удалять"
                @click.native="$rir.modal.close()"
              />
              <r-button
                class="flex-1"
                @click.native="Delete"
                width="wide"
                title="Да, удалить"
              />
            </div>
            <p
              class="error color-fargo mt-3"
              v-if="error"
            >
              {{ error }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';

export default {
  name: 'DeleteModal',
  props: {
    id: {
      type: Number
    },
    typeAction: {
      type: String
    },
    name: {
      type: String
    }
  },
  data() {
    return {
      upload: false,
      error: ''
    };
  },
  methods: {
    async Delete() {
      await new Api()[this.$props.typeAction](this.$props.id).then(res => {
        if (res?.error) {
          this.error = res.error;
        } else {
          const r = this.$router.resolve({
            name: 'index'
          });
          window.location.assign(r.href);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
</style>
