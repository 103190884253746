<template>
  <div>
    <h1 class="mb-7">
      {{ title }}
    </h1>
    <div class="file-cont">
      <a
        class="color-rocky flex align-end"
        :href="file.url"
        :download="file.filename"
        v-for="file in files"
        :key="file.url"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="file"
        />
        {{ file.filename.split('.')[0] }}
      </a>
    </div>
    <div
      class="modal-preview"
      v-html="content"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalPreview',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.file-cont {
  column-gap: 24px;
  display: flex;
}
::v-deep .modal-preview {
  h2 {
    margin-top: 24px;
    margin-bottom: 12px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    opacity: 0.72;
  }
  a {
    text-decoration: underline;
    color: var(--r-rocky);
    text-decoration-color: var(--r-rocky);
  }

  ul,
  ol {
    padding: 0 1.2rem;
  }
}
</style>
